import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PageTitle from '../../atoms/PageTitle';
import { getValueByTarget, VehiclesOptionCount } from '../../../utils/parse';
import {
  DriverDispatchProducts,
  WeekendUsageOptions,
  MonthlyMosilerOption,
  RecruitPurposeOption,
  RouteOptions,
  ConciergeAdviceOptions,
  EtcRequestOptions,
  UsageTimeOptionsDriverDispatch,

  GenderOption,
  DriverSmokingStatusOption,
  DriverParkingSupportOption,
  CommutingVehicleProvidedOption,
  ForeignLanguageProficiencyOption,
  DriverWaitingRoomAvaliableOption,
  EducationalBackgroundOption,
  UsageTimeOptionsDriverDispatchPagyun
} from '../../../utils/EstimateFormData';
import Button from '../../atoms/Button';
import { URLS } from '../../../utils/urls';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListBox from '../../atoms/ListBox';
import TotalAmount from '../../atoms/TotalAmount';
import useCheckPC from '../../../hooks/useCheckPC';
import ListWithBullet from '../../atoms/ListWithBullet';
import { getCalcSalaryDetailEstimate } from '../../../lib/DriverDispatchApi';
import moment from 'moment';
import { SetMmEstimateHistory, setSheetEstimateResponse } from '../../../lib/EstimateApi';
import { closeLoading, closeLoadingWithEstimate, openLoading, openLoadingWithEstimate } from '../../../reducers/loadingRedux';
import TotalAmountDriverDispatch from '../../atoms/TotalAmountDriverDispatch';
import { getDdEstimateCookieDataAndDecrypt, isDev } from '../../../lib/Util';
import ListBoxWithIcon from '../../atoms/ListBoxWithIcon';
import ListBox3 from '../../atoms/ListBox3';
import NewListBox from '../../atoms/NewListBox';
import ListBoxWithIcon2 from '../../atoms/ListBoxWithIcon2';
import CheckBox2 from '../../atoms/CheckBox2';
import { useForm } from 'react-hook-form';
import CheckBox2Custom from '../../atoms/CheckBox2Custom';
import { SendSlackDdContractComplete2 } from '../../../lib/SlackApi';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, ESTIMATE_TYPE_DRIVER_DISPATCH, PAGE } from '../../../utils/constant';



/**
 * 견적 확인 페이지
 * name : confirmEstimate
 * */

const ConfirmDispatchRequest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isPC } = useCheckPC();
  const { DriverDispatchOption } = useSelector((state) => state);

  const { handleSubmit, setValue, register, setError, getValues, formState: { errors }, clearErrors } = useForm({

  });


  /** 상품명 **/
  // DriverDispatchOption?.subscription
  const [subscription, setSubscription] = useState([]);

  /** 기본정보 **/
  const [basicInfomation, setbasicInfomation] = useState([]);

  const [basicOption, setbasicOption] = useState([
    {
      title: `연 4회 맞춤형 기사 교육(분기당 1회)`,
    },
    {
      title: `기사 자동 관리 프로그램 제공`,
    }
  ]);

  /** 선택옵션 **/
  const [optionData, setOptionData] = useState([]);

  /** 상세견적 (임금) **/
  const [estimateStep1, setEstimateStep1] = useState([]);

  /** 상세견적 (제세공과금) **/
  const [estimateStep2, setEstimateStep2] = useState([]);

  /** 상세견적 (관리비용) **/
  const [estimateStep3, setEstimateStep3] = useState([]);

  /** 상세견적 (이익금) **/
  const [estimateStep4, setEstimateStep4] = useState([]);

  /** 견적 총 금액 **/
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState([
    { price: 0 }
  ]);

  const [recruitRequestInfo, setRecruitRequestInfo] = useState([]);

  const timeData = `${DriverDispatchOption?.basicInfo1?.workStartTime}~${DriverDispatchOption?.basicInfo1?.workEndTime}`

  const [checkSave1, setCheckSave1] = useState(false);
  const [checkSave2, setCheckSave2] = useState(false);
  const [globalCookieInfo, setGlobalCookieInfo] = useState({
    UserName: '',
    PhoneNumber: '',
    EmailAddress: ''
  });


  /** 견적 총 금액 **/
  const [ConfirmEstimateDescription, setConfirmEstimateDescription] = useState([
    '본 견적은 채용 수수료에 대한 견적이며, 채용 인원의 급여는 직접 지급해 주시기 바랍니다',
    '실제 계약에 반영되는 내용에 따라 일부 조정될 수 있습니다.',
  ]);

  const getAgeToStr2 = (obj) => {
    let retData = ''
    if (obj?.age20) retData += '20대,'
    if (obj?.age30) retData += '30대,'
    if (obj?.age40) retData += '40대,'
    if (obj?.age50) retData += '50대,'
    if (obj?.age60) retData += '60대,'
    if (obj?.ageboth) retData += '연령무관,'
    retData += '!@#'
    retData = retData.replace(',!@#', '')
    return retData;
  }

  const truncateString = (str, length) => {
    if (str?.length > length) {
      return str.substring(0, length) + "...";
    } else {
      return str;
    }
  }

  const unmount = () => {
    if (isPC) {
      document.getElementById('bookingboxId').style.marginBottom = 500 + 'px';
    }
    else {
      document.getElementById('bookingboxId').style.marginBottom = 500 + 'px';
    }
  }
  const mount = () => {
    if (isPC) {
      document.getElementById('bookingboxId').style.marginBottom = 250 + 'px';
    }
    else {
      document.getElementById('bookingboxId').style.marginBottom = 250 + 'px';
    }
  }

  useEffect(() => {
    mount();
    return () => unmount()
  }, [])


  // 견적서 구글에 전송
  useEffect(() => {
    const initSendSheet = async () => {
      dispatch(openLoadingWithEstimate())

      let ProductStr = `${getValueByTarget(DriverDispatchProducts, DriverDispatchOption?.subscription, 'sheetData')}${getValueByTarget(UsageTimeOptionsDriverDispatch, DriverDispatchOption?.usageTime, 'title')}`


      /*let ProductStr = `${getValueByTarget(DriverDispatchProducts, DriverDispatchOption?.subscription, 'sheetData')}
    ${DriverDispatchOption?.basicInfo1?.workStartTime}~${DriverDispatchOption?.basicInfo1?.workEndTime}
    휴일이용:${(DriverDispatchOption?.basicInfo1?.weekendUsage === 'etc') ? `기타(${DriverDispatchOption?.basicInfo1?.weekendUsageEtcString})` : getValueByTarget(WeekendUsageOptions, DriverDispatchOption?.basicInfo1?.weekendUsage, 'title')}
    연봉:${DriverDispatchOption?.basicInfo2?.selectedSalary}만원
    옵션:${getValueByTarget(RecruitPurposeOption, DriverDispatchOption?.options?.recruitPurpose, 'sheetData')}, ${getValueByTarget(MonthlyMosilerOption, DriverDispatchOption?.options?.monthlyMosilerDemo, 'sheetData')}`*/

      let cookieInfo = getDdEstimateCookieDataAndDecrypt()
      setGlobalCookieInfo(getDdEstimateCookieDataAndDecrypt())

      if (!isDev()) {
        await setSheetEstimateResponse({
          UserName: cookieInfo.UserName,
          UserPhone: cookieInfo.PhoneNumber,
          UserEmail: cookieInfo.EmailAddress,
          StartAddress: DriverDispatchOption?.basicInfo2?.garageAddress,
          EndAddress: DriverDispatchOption?.basicInfo2?.companyAddress,
          UsageSchedule: moment(DriverDispatchOption?.basicInfo1?.usageDate).format('yyyy-MM-DD'),
          // Route: (DriverDispatchOption?.basicInfo3?.route === 'route_etc') ? `기타(${DriverDispatchOption?.basicInfo3?.routeEtcString})` : getValueByTarget(RouteOptions, DriverDispatchOption?.basicInfo3?.route, 'title'),
          Route: '',
          Requestcomment: (DriverDispatchOption?.basicInfo3?.etcRequest === 'yes') ? `있음(${DriverDispatchOption?.basicInfo3?.etcRequestEtcString})` : getValueByTarget(EtcRequestOptions, DriverDispatchOption?.basicInfo3?.etcRequest, 'title'),
          ConciergeString: (DriverDispatchOption?.basicInfo3?.conciergeAdvice === 'etc') ? `기타(${DriverDispatchOption?.basicInfo3?.conciergeAdviceEtcString})` : getValueByTarget(ConciergeAdviceOptions, DriverDispatchOption?.basicInfo3?.conciergeAdvice, 'title'),
          // NeedsString: getValueByTarget(RecruitPurposeOption, DriverDispatchOption?.options?.recruitPurpose, 'title'),
          NeedsString: '',
          SelectedProductStr: ProductStr,
          MmOptionVehicles: '',
          MmOptionCarAmount: '',
          MmOptionInsurance: '',
          MmOptionPassenger: '',
          MmOptionService: '',
          DdOptionMonthlyMosiler: getValueByTarget(MonthlyMosilerOption, DriverDispatchOption?.options?.monthlyMosilerDemo, 'title'),
          DdOptionRecruitPurpose: getValueByTarget(RecruitPurposeOption, DriverDispatchOption?.options?.recruitPurpose, 'title'),
        })
      }

      dispatch(closeLoadingWithEstimate())
    }


    initSendSheet()
  }, [])


  useEffect(() => {

    const YearSalary = Number(DriverDispatchOption?.basicInfo2?.selectedSalary) * 10000

    /** 상품명 **/
    const updatedSubscription = [
      {
        title: getValueByTarget(
          DriverDispatchProducts,
          DriverDispatchOption.subscription,
          'title'
        )
        // ,
        // price: getValueByTarget(
        //   DriverDispatchProducts,
        //   DriverDispatchOption.subscription,
        //   'price'
        // )
      }]
    setSubscription(updatedSubscription)


    /** 기본정보 **/
    const updateBasicInfomation = [
      {
        title: '상품',
        value: DriverDispatchOption.subscription === 'recruit' ? 
          getValueByTarget(UsageTimeOptionsDriverDispatch, DriverDispatchOption?.usageTime, 'subtitle2') : 
          getValueByTarget(UsageTimeOptionsDriverDispatchPagyun, DriverDispatchOption?.usageTime, 'subtitle2'),
      },
      {
        title: `차고지 주소`,
        value: `${DriverDispatchOption?.basicInfo2?.garageAddress}`,
      },
      {
        title: `회사 주소`,
        value: `${DriverDispatchOption?.basicInfo2?.companyAddress}`,
      },
      {
        title: `필요 시점`,
        value: `${moment(DriverDispatchOption?.basicInfo1?.usageDate).format('yyyy년 MM월 DD일')}`,
      },
    ]
    setbasicInfomation(updateBasicInfomation)

    /** 옵션 **/
    const updateOptionInfo = [
      {
        title: `면접자 1일 운행 체험 서비스`,
        value: `${getValueByTarget(MonthlyMosilerOption, DriverDispatchOption?.options?.monthlyMosilerDemo, 'title')}`
      },
      {
        title: '채용 목적',
        value: getValueByTarget(RecruitPurposeOption, DriverDispatchOption?.options?.recruitPurpose, 'title'),
      }
    ]
    setOptionData(updateOptionInfo)


    /** 견적 상세 정보 **/
    {
      let obj = getCalcSalaryDetailEstimate(YearSalary)
      const totalToHapgye = (str) => {
        return (str === 'total') ? '합계' : str
      }

      const updateEstimateStep1 = []
      for (let i in Object.keys(obj.step1)) {
        let str = Object.keys(obj.step1)[i]
        updateEstimateStep1.push({ title: totalToHapgye(str), price: obj.step1[str] })
      }

      const updateEstimateStep2 = []
      for (let i in Object.keys(obj.step2)) {
        let str = Object.keys(obj.step2)[i]
        updateEstimateStep2.push({ title: totalToHapgye(str), price: obj.step2[str] })
      }

      const updateEstimateStep3 = []
      for (let i in Object.keys(obj.step3)) {
        let str = Object.keys(obj.step3)[i]
        updateEstimateStep3.push({ title: totalToHapgye(str), price: obj.step3[str] })
      }

      const updateEstimateStep4 = []
      for (let i in Object.keys(obj.step4)) {
        let str = Object.keys(obj.step4)[i]
        updateEstimateStep4.push({ title: totalToHapgye(str), price: obj.step4[str] })
      }

      setEstimateStep1(updateEstimateStep1)
      setEstimateStep2(updateEstimateStep2)
      setEstimateStep3(updateEstimateStep3)
      setEstimateStep4(updateEstimateStep4)

      setEstimatedTotalAmount([{
        title: '총 금액 (임금 + 제세공과금 + 관리비용 + 이익금)',
        price: obj.totalEstimateAmount
      }])
    }

    const tmpRecruitRequestInfo = []

    tmpRecruitRequestInfo.push({ title: '출근일', value: moment(DriverDispatchOption?.basicInfo1?.usageDate).format('yyyy-MM-DD') })
    tmpRecruitRequestInfo.push({ title: '성별', value: getValueByTarget(GenderOption, DriverDispatchOption?.recruitRequest?.gender, 'title') })
    tmpRecruitRequestInfo.push({ title: '연령', value: getAgeToStr2(DriverDispatchOption?.recruitRequest) })
    tmpRecruitRequestInfo.push({ title: '기사 흡연 여부', value: getValueByTarget(DriverSmokingStatusOption, DriverDispatchOption?.recruitRequest?.driverSmokingStatus, 'title') })
    tmpRecruitRequestInfo.push({ title: '출퇴근 차량 제공', value: getValueByTarget(DriverParkingSupportOption, DriverDispatchOption?.recruitRequest?.driverParkingSupport, 'title') })
    if (DriverDispatchOption?.recruitRequest?.commutingVehicleProvided === 'impossible') {
      tmpRecruitRequestInfo.push({ title: '기사 주차 지원', value: getValueByTarget(CommutingVehicleProvidedOption, DriverDispatchOption?.recruitRequest?.commutingVehicleProvided, 'title') })
    }
    tmpRecruitRequestInfo.push({ title: '입사 후 담당업무', value: DriverDispatchOption?.recruitRequest?.postHireJobResponsibilities })
    tmpRecruitRequestInfo.push({ title: '복리후생', value: DriverDispatchOption?.recruitRequest?.benefits })
    tmpRecruitRequestInfo.push({ title: '차량모델명', value: DriverDispatchOption?.recruitRequest?.vehicleModelName })
    tmpRecruitRequestInfo.push({ title: '외국어 가능여부', value: getValueByTarget(ForeignLanguageProficiencyOption, DriverDispatchOption?.recruitRequest?.foreignLanguageProficiency, 'title') })
    if (DriverDispatchOption?.recruitRequest?.foreignLanguageProficiency === 'possible') {
      tmpRecruitRequestInfo.push({ title: '가능언어', value: DriverDispatchOption?.recruitRequest?.foreignLanguageProficiencyStr })
    }
    tmpRecruitRequestInfo.push({ title: '기사 대기실 유무', value: getValueByTarget(DriverWaitingRoomAvaliableOption, DriverDispatchOption?.recruitRequest?.driverWaitingRoomAvailable, 'title') })
    tmpRecruitRequestInfo.push({ title: '학력', value: getValueByTarget(EducationalBackgroundOption, DriverDispatchOption?.recruitRequest?.educationalBackground, 'title') })
    tmpRecruitRequestInfo.push({ title: '특이사항', value: DriverDispatchOption?.recruitRequest?.specialNotes })

    setRecruitRequestInfo(tmpRecruitRequestInfo)

  }, [DriverDispatchOption])

  return (
    <>
      <div className={classNames('booking-wrap')}>
        <PageTitle title={'파견 의뢰서 확인'} subtitle={'모시러 파견 의뢰서'} />
        <div className={classNames('confirm-booking-wrap')}>
          <div className={classNames('confirm-booking-content')} style={{ width: '100%' }}>

            {isPC ? (
              <div className={classNames('new-list-box')}>
                <div className={classNames('estimate')}>
                  <div className={classNames('estimate-sub')}>
                    <div className={classNames('title')}>PREPARED FOR</div>
                    <div className={classNames('content')}>
                      <div><strong>{globalCookieInfo.UserName}</strong> 고객님</div>
                      <div>{globalCookieInfo.EmailAddress}</div>
                    </div>
                  </div>
                  <div className={classNames('estimate-sub')}>
                    <div className={classNames('title')}>PREPARED BY</div>
                    <div className={classNames('content')}>
                      <div>(주)버틀러</div>
                      <div>463-87-00162</div>
                      <div>서울 강남구 역삼로 160 5층</div>
                    </div>
                  </div>

                  <div className={classNames('estimate-sub-date')}>
                    <div className={classNames('flex')}>
                      <div className={classNames('title')}>DATE OF REQUEST</div>
                      <div className={classNames('date')}>{moment().format('yyyy년 MM월 DD일')}</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className={classNames('new-list-box')}>
                  <div className={classNames('estimate')}>
                    <div className={classNames('estimate-sub')}>
                      <div className={classNames('title')}>PREPARED FOR</div>
                      <div className={classNames('content')}>
                        <div><strong>{globalCookieInfo.UserName}</strong> 고객님</div>
                        <div>{globalCookieInfo.EmailAddress}</div>
                      </div>
                    </div>
                    <div className={classNames('estimate-sub')}>
                      <div className={classNames('title')}>PREPARED BY</div>
                      <div className={classNames('content')}>
                        <div>(주)버틀러</div>
                        <div>463-87-00162</div>
                        <div>서울 강남구 역삼로 160 5층</div>
                      </div>
                    </div>
                  </div>

                  <div className={classNames('new-list-box')}>
                    <div className={classNames('estimate')}>
                      <div className={classNames('estimate-sub-date')}>
                        <div className={classNames('flex')}>
                          <div className={classNames('title')}>DATE OF REQUEST</div>
                          <div className={classNames('date')}>{moment().format('yyyy년 MM월 DD일')}</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </>

            )}



            {/* Options */}
            <div className={classNames('new-list-box')} style={{ flexDirection: 'column', flexShrink: '0' }}>
              <div>
                <div className={classNames('option title')}>OPTIONS 1</div>
                <div className={classNames('option wrap')}>
                  {basicInfomation.map((item, idx) => (
                    <div key={idx} className={classNames('option list')}>
                      <div >{item.title}</div>
                      <div >{item.value}</div>
                    </div>
                  ))}
                  <br />

                  {optionData.map((item, idx) => (
                    <div key={idx} className={classNames('option list')}>
                      <div >{item.title}</div>
                      <div >{item.value}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div className={classNames('option title')}>OPTIONS 2</div>
                <div className={classNames('option wrap')}>
                  {recruitRequestInfo.map((item, idx) => (
                    <div key={idx} className={classNames('option list')}>
                      <div >{item.title}</div>
                      <div >{item.value}</div>
                    </div>
                  ))}
                  <br />

                </div>
              </div>
            </div>


            <div className={classNames('new-list-box')}>
              <div className={classNames('sub title')}>
                <div>유의사항</div>
              </div>
              <div>
                <div style={{ border: '1px solid #d6d6d6', padding: '10px 15px', backgroundColor: '#f6f6f6' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ display: 'inline-block', color: 'var(--blue)' }}>아래 유의사항을 모두 확인하셨습니까?</span>
                    <span style={{ display: 'inline-block', float: 'right' }}>
                      <CheckBox2Custom
                        onFocus={e => e.target.blur()}
                        style={{ textAlign: 'right' }}
                        register={'checkbox01'}
                        label={'확인함'}
                        checked={checkSave1}
                        onClick={e => {
                          setCheckSave1(e.target.checked)
                          if (e.target.checked === true) {
                            clearErrors('checkbox01')
                          }
                        }}

                      />
                      {errors?.checkbox01?.message && <span className={classNames('error')}>{errors?.checkbox01?.message}</span>}
                    </span>
                  </div>
                </div>

                <div style={{ border: '1px solid #d6d6d6', padding: '10px 10px' }}>
                  <div>
                    - 계약기간
                  </div>
                  <div>
                    본 파견 의뢰는 {globalCookieInfo.UserName}(이하 ‘고객’)와 (주)버틀러(이하 ‘파견업체’) 간 체결한 계약 건으로 보고, 계약의 기간은 {moment().format('yyyy년 MM월 DD일')}부터로 정하며 어느 일방이 계약해지의 의사표시를 명시적으로 하지 않는 한 계약은 유효한 것으로
                    본다.
                  </div>
                  <br />

                  <div>
                    - 파견 의뢰 서비스 범위
                  </div>
                  <div>
                    ‘파견업체’가 ‘고객’에게 제공하는 파견 의뢰 서비스 범위는
                    ① ‘고객’이 요구하는 직무의 인원 모집
                    ② 채용공고 기획 및 매체진행
                    ③ 서류전형 및 필터링
                    ④ 응대문의
                    ⑤ 면접일정 및 1차 통보
                    ⑥ 불합격자의 통보
                  </div>
                  <br />

                  <div>
                    - 파견 의뢰 서비스 수수료
                  </div>
                  <div>
                    견적서에 표기된 금액 외 ‘파견업체’의 별도 수수료는 없는 것으로 정하나 최종 파견 인원의 조건에 따라 일부 변동될 수
                    있고, ‘고객’이 상기 서비스 범위를 벗어나는 업무 등을 ‘파견업체’에게 위임 처리한 경우 별도 비용을 정산한다.
                  </div>

                </div>
              </div>
            </div>

            <div className={classNames('new-list-box')}>

              <div className={classNames('sub title')}>
                <div>의무사항</div>
              </div>
              <div>
                <div style={{ border: '1px solid #d6d6d6', padding: '10px 15px', backgroundColor: '#f6f6f6' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ display: 'inline-block', color: 'var(--blue)', wordBreak: 'keep-all' }}>아래 의무사항을 모두 확인하였으며 동의합니다.</span>
                    <span style={{ display: 'inline-block', }}>
                      <CheckBox2Custom
                        onFocus={e => e.target.blur()}
                        register={'checkbox02'}
                        label={'동의함'}
                        checked={checkSave2}
                        onClick={e => {
                          setCheckSave2(e.target.checked)
                          if (e.target.checked === true) {
                            clearErrors('checkbox02')
                          }
                        }}
                      />
                      {errors?.checkbox02?.message && <span className={classNames('error')}>{errors?.checkbox02?.message}</span>}
                    </span>
                  </div>
                </div>
                <div style={{ border: '1px solid #d6d6d6', padding: '10px 10px' }}>
                  <div>
                    - 금지행위
                  </div>
                  <div>
                    {globalCookieInfo.UserName}(이하 ‘고객’)는 (주)버틀러(이하 ‘파견업체’)로부터 추천 받은 모든 인원에 대해 ‘파견업체’를 통하지 않고
                    직접 채용을 포함해 그 어떤 형태의 업무 의뢰도 할 수 없으며, 이를 위반할 경우 견적서 내 총 비용의 30배를 ‘파견업체’에
                    배상해야 한다.
                  </div>
                  <div>
                    이는 해당 인원을 추천 받은 날로부터 2년간 유효하다.
                  </div>
                  <br />

                  <div>
                    - 정보제공
                  </div>
                  <div>
                    ‘고객’은 파견 의뢰를 수행하기 위한 ‘파견업체’의 정보 제공 요청에 신실히 응해야 하고, ‘파견업체’는 업무 진행을 위해
                    정보를 활용할 수 있으나 이는 파견 의뢰 업무 외 제 3자에게 제공 또는 누설할 수 없다.
                  </div>
                  <br />

                  <div>
                    - 보안유지
                  </div>
                  <div>
                    ‘고객’, ‘파견업체’는 파견 의뢰를 진행함에 있어 취득한 상대방의 정보 일체를 비밀 유지해야 하며, 위반하여 상대방에게
                    손해를 입혔을 경우 손해배상의 책임을 진다
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classNames('booking-form-summary2')}>
            <div>
              <div className={classNames('button-set')}>
                <Button
                  onClick={
                    () => navigate(URLS.DRIVER_DISPATCH_USER_INFORMATION)
                  }
                >이전</Button>
                <Button
                  styleType={'secondary'}
                  onClick={
                    async () => {
                      let valid = true

                      if (!checkSave1) {
                        valid = false
                        setError('checkbox01', {
                          type: 'focus',
                          name: 'checkbox01',
                          message: '유의사항 확인 후 확인함을 체크해주세요.'
                        }, { shouldFocus: true });

                      }
                      if (!checkSave2) {
                        valid = false
                        setError('checkbox02', {
                          type: 'focus',
                          name: 'checkbox02',
                          message: '의무사항 확인 후 동의함을 체크해주세요.'
                        }, { shouldFocus: true });
                      }

                      if (valid) {


                        SetMmEstimateHistory({
                          ...getDdEstimateCookieDataAndDecrypt(),
                          EstimateType: ESTIMATE_TYPE_DRIVER_DISPATCH,
                          EstimatePage: PAGE.PAGE_09,
                          JsonData: JSON.stringify(DriverDispatchOption)
                        })

                        let ProductStr = `${getValueByTarget(DriverDispatchProducts, DriverDispatchOption?.subscription, 'sheetData')}${getValueByTarget(UsageTimeOptionsDriverDispatch, DriverDispatchOption?.usageTime, 'title')}`
                        let obj = {
                          UserType: (DriverDispatchOption?.userInformation?.userType === 'personal') ? '개인' : '법인',
                          ContractName: DriverDispatchOption?.userInformation?.user?.name,
                          ContractProduct: ProductStr
                        }
                        await SendSlackDdContractComplete2(obj)

                        navigate(URLS.DRIVER_DISPATCH_COMPLETE_CONTRACT)
                      }

                    }
                  }
                >다음</Button>
              </div>
            </div>
          </div>

        </div >
      </div >

    </>
  )
}

export default ConfirmDispatchRequest;