/************** 수행기사 파견 ************** */
export const OptionDictDriverDispatch = {
  dispatch: {
    category: 'subscription',
    amount: 2900000
  },
  recruit: {
    category: 'subscription',
    amount: 2280000
  },
  mmnothing: {
    category: 'monthlyMosilerDemo',
    amount: 0
  },
  usage1month: {
    category: 'monthlyMosilerDemo',
    amount: 2640000
  },

  driver: {
    category: 'recruitPurpose',
    amount: 0
  },
  housekeeper: {
    category: 'recruitPurpose',
    amount: 300000
  },

}

export const DriverDispatchProducts = [
  {
    title: '채용 상품',
    category: OptionDictDriverDispatch.recruit.category,
    value: 'recruit',
    subtitle: '모시러의 드라이버를 직접 고용할 수 있는 헤드헌팅 상품',
    price: OptionDictDriverDispatch.recruit.amount,
    sheetData: '[채용]',
    ProductType: 2,
    description: [
      '드라이버 직접 고용하는 과정에서 오는 스트레스를 해소해드립니다.',
      '모시러에서 직접 교육 및 인터뷰를 진행한 수행기사를 고용해 보세요.',
      '구독 또는 파견상품 이용 후 채용 결정 가능합니다.',
    ]
  },
  {
    title: '파견 상품',
    category: OptionDictDriverDispatch.dispatch.category,
    value: 'dispatch',
    subtitle: '모시러의 드라이버를 직접 고용하지 않고 안정적으로 이용할 수 있는 상품',
    price: OptionDictDriverDispatch.dispatch.amount,
    sheetData: '[파견]',
    ProductType: 1,
    description: [
      '근태 관리 뿐만 아니라 차량관리/이용내역을 자체 ERP통해 확인 가능합니다',
      '분기별 정기 서비스 교육을 통해 드라이버 수준을 유지합니다',
      '서비스 이용 후 드라이버 성향이 맞지 않다면 교체도 가능합니다.',
    ]
  }
]


// 주말 및 공휴일 이용여부
export const WeekendUsageOptions = [
  {
    title: '사용 안함',
    category: 'weekendUsage',
    value: 'no',
  },
  {
    title: '월 2일 미만',
    category: 'weekendUsage',
    value: 'month_2_down',
  },
  {
    title: '월 3일 ~ 5일 미만',
    category: 'weekendUsage',
    value: 'month_3_5',
  },
  {
    title: '5일 이상',
    category: 'weekendUsage',
    value: 'month_5_up',
  },
  {
    title: '기타',
    category: 'weekendUsage',
    value: 'etc',
  },
]



// 채용 - 연봉(?)
// 파견 - 연봉
// 연봉
// export const SalaryOptions = [
//   {
//     title: '4000만원',
//     category: 'selectedSalary',
//     value: '4000',
//   },
//   {
//     title: '5000만원',
//     category: 'selectedSalary',
//     value: '5000',
//   },
//   {
//     title: '6000만원',
//     category: 'selectedSalary',
//     value: '6000',
//   },
//   {
//     title: '7000만원',
//     category: 'selectedSalary',
//     value: '7000',
//   },
// ]

export const SalaryOptions = [
  {
    title: '4,000 ~ 5,000만원',
    category: 'selectedSalary',
    value: '4500',
  },
  {
    title: '5,000 ~ 6,000만원',
    category: 'selectedSalary',
    value: '5500',
  }
]

// 컨시어지 상담 가능 시간대
export const ConciergeAdviceOptions = [
  {
    title: '오전',
    category: 'conciergeAdvice',
    value: 'am',
  },
  {
    title: '오후',
    category: 'conciergeAdvice',
    value: 'pm',
  },
  // {
  //   title: '상관없음',
  //   category: 'conciergeAdvice',
  //   value: 'nothing',
  // },
  // {
  //   title: '기타',
  //   category: 'conciergeAdvice',
  //   value: 'etc',
  // },
]

// 별도 요청 사항
export const EtcRequestOptions = [
  {
    title: '없음',
    category: 'etcRequest',
    value: 'no',
  },
  {
    title: '있음',
    category: 'etcRequest',
    value: 'yes',
  }
]

// 알게된 경로 : RouteOptions 기존꺼 활용



/** 옵션 **/
export const MonthlyMosilerOption = [
  {
    title: '이용 안함',
    category: OptionDictDriverDispatch.mmnothing.category,
    value: 'mmnothing',
    price: OptionDictDriverDispatch.mmnothing.amount,
    sheetData: '면접자 1일 운행 서비스 미이용',
    optionValue: 0x0001
  },
  {
    title: '이용함',
    category: OptionDictDriverDispatch.usage1month.category,
    value: 'usage1month',
    price: OptionDictDriverDispatch.usage1month.amount,
    sheetData: '면접자 1일 운행 서비스 이용',
    optionValue: 0x0002
  }
]

export const RecruitPurposeOption = [
  {
    title: '법인수행기사',
    category: OptionDictDriverDispatch.driver.category,
    value: 'driver',
    price: OptionDictDriverDispatch.driver.amount,
    sheetData: '법인기사',
    optionValue: 0x0010
  },
  {
    title: '사택기사',
    category: OptionDictDriverDispatch.housekeeper.category,
    value: 'housekeeper',
    price: OptionDictDriverDispatch.housekeeper.amount,
    sheetData: '법인기사+사택기사',
    optionValue: 0x0020
  }
]





/************** 월간모시러 정보 ************** */
export const OptionDict = {
  annual: {
    category: 'subscription',
    amount: 2900000
  },
  monthly: {
    category: 'subscription',
    amount: 2280000
  },
  day10: {
    category: 'usageTime',
    amount: (2280000 - 2280000)
  },
  day14: {
    category: 'usageTime',
    amount: (2950000 - 2280000)
  },
  day20: {
    category: 'usageTime',
    amount: (3960000 - 2280000)
  },
  day30: {
    category: 'usageTime',
    amount: (5540000 - 2280000)
  },
  day30_2: {
    category: 'usageTime',
    amount: (6930000 - 2280000)
  },
  annual_1year_100h: {
    category: 'usageTime',
    amount: (2900000 - 2900000)
  },
  annual_1year_200h: {
    category: 'usageTime',
    amount: (5900000 - 2900000)
  },
  annual_1year_300h: {
    category: 'usageTime',
    amount: (7800000 - 2900000)
  },
  annual_1year_250h_pm: {
    category: 'usageTime',
    amount: (6250000 - 2900000)
  },
  annual_1year_500h_pm: {
    category: 'usageTime',
    amount: (11500000 - 2900000)
  },
  oneVehicle: {
    category: 'numberOfVehicles',
    amount: 0
  },
  twoVehicles: {
    category: 'numberOfVehicles',
    amount: 0
  },
  threeVehicles: {
    category: 'numberOfVehicles',
    amount: 150000
  },
  driver: {
    category: 'roleOfVehicles',
    amount: 0
  },
  business: {
    category: 'roleOfVehicles',
    amount: 500000
  },
  business_10: {
    category: 'roleOfVehicles',
    amount: 200000
  },
  business_14: {
    category: 'roleOfVehicles',
    amount: 280000
  },
  business_20: {
    category: 'roleOfVehicles',
    amount: 400000
  },
  business_30: {
    category: 'roleOfVehicles',
    amount: 500000
  },

  // 신규추가 begin
  twomilliondown: {
    category: 'carAmount',
    amount: 0
  },
  twomillionup: {
    category: 'carAmount',
    amount: 100000
  },

  insubasic: {
    category: 'insurance',
    discount: 0
  },
  insucustomer: {
    category: 'insurance',
    discount: 100000
  },

  persontwo: {
    category: 'passengerPerson',
    amount: 0
  },
  personfour: {
    category: 'passengerPerson',
    amount: 500000
  },


  // 신규추가 end

  month1: {
    category: 'periodOfContract',
    discount: 0
  },
  month4: {
    category: 'periodOfContract',
    discount: 300000
  },
  month12: {
    category: 'periodOfContract',
    discount: 500000
  },
}

export const BookingProducts = [
  {
    title: '월간모시러',
    category: OptionDict.monthly.category,
    value: 'monthly',
    subtitle: '월 단위로 정기 구독하는 서비스',
    price: OptionDict.monthly.amount,
    description: [
      '월 단위로 필요한 날에 수행기사 서비스를 이용할 수 있는 구독 상품입니다.',
      '한달에 10일 이상 정기적으로 이용하시는 분들께 추천합니다.',
      '기업 임직원 분들이 가장 보편적으로 이용하는 상품입니다.'
    ]
  },
  {
    title: '연간모시러',
    category: OptionDict.annual.category,
    value: 'annual',
    subtitle: '연 단위로 정기 구독하는 서비스',
    price: OptionDict.annual.amount,
    description: [
      '1년 내 필요한 일정에 맞춰 수행기사 서비스를 이용할 수 있는 구독상품입니다.',
      '골프 라운드나 지방 출장이 있으신 분들에게 추천합니다.',
      '수행기사를 고용하지 않고 유연하고 합리적으로 이용할 수 있습니다.',
    ]
  },
]

export const UsageTimeOptions = [
  {
    title: '10일 상품',
    category: OptionDict.day10.category,
    value: 'day10',
    subtitle: '월 80시간 1일 최소 8시간 이상 이용',
    price: OptionDict.day10.amount,
    description: [
      '하루 최소 8시간 기준으로, 한달에 총 <i class="blue">80시간</i> 이상 이용 가능합니다.',
      '고객님이 선택하신 상품의 시간당 단가는 <i class="blue">28,700원</i> 입니다.',
      '해당 상품의 드라이버 풀은 <i class="blue">5명</i> 입니다.',
      '추천 드라이버 선정은 <i class="blue">5명</i> 입니다.'
    ]
  },
  {
    title: '14일 상품',
    category: OptionDict.day14.category,
    value: 'day14',
    subtitle: '월 112시간 1일 최소 8시간 이상 이용',
    price: OptionDict.day14.amount,
    description: [
      '하루 최소 8시간 기준으로, 한달에 총 <i class="blue">112시간</i> 이상 이용 가능합니다.',
      '고객님이 선택하신 상품의 시간당 단가는 <i class="blue">26,000원</i> 입니다.',
      '해당 상품의 드라이버 풀은 <i class="blue">3명</i> 입니다.',
      '추천 드라이버 선정은 <i class="blue">10명</i> 입니다.'
    ]
  },
  {
    title: '20일 상품',
    category: OptionDict.day20.category,
    value: 'day20',
    subtitle: '월 112시간 1일 최소 8시간 이상 이용',
    price: OptionDict.day20.amount,
    description: [
      '하루 최소 8시간 기준으로, 한달에 총 <i class="blue">112시간</i> 이상 이용 가능합니다.',
      '고객님이 선택하신 상품의 시간당 단가는 <i class="blue">26,000원</i> 입니다.',
      '해당 상품의 드라이버 풀은 <i class="blue">3명</i> 입니다.',
      '추천 드라이버 선정은 <i class="blue">10명</i> 입니다.'
    ]
  },
  {
    title: '30일 상품',
    category: OptionDict.day30.category,
    value: 'day30',
    subtitle: '월 112시간 1일 최소 8시간 이상 이용',
    price: OptionDict.day30.amount,
    description: [
      '하루 최소 8시간 기준으로, 한달에 총 <i class="blue">112시간</i> 이상 이용 가능합니다.',
      '고객님이 선택하신 상품의 시간당 단가는 <i class="blue">26,000원</i> 입니다.',
      '해당 상품의 드라이버 풀은 <i class="blue">3명</i> 입니다.',
      '추천 드라이버 선정은 <i class="blue">10명</i> 입니다.'
    ]
  },
  {
    title: '40일 상품',
    category: OptionDict.day30_2.category,
    value: 'day30_2',
    subtitle: '월 112시간 1일 최소 8시간 이상 이용',
    price: OptionDict.day30_2.amount,
    description: [
      '하루 최소 8시간 기준으로, 한달에 총 <i class="blue">112시간</i> 이상 이용 가능합니다.',
      '고객님이 선택하신 상품의 시간당 단가는 <i class="blue">26,000원</i> 입니다.',
      '해당 상품의 드라이버 풀은 <i class="blue">3명</i> 입니다.',
      '추천 드라이버 선정은 <i class="blue">10명</i> 입니다.'
    ]
  },
]

export const UsageTimeOptionsMonthly = [
  {
    title: '10일 상품',
    category: OptionDict.day10.category,
    value: 'day10',
    // subtitle: '월 80시간 1일 최소 8시간 이상 이용',
    subtitle: '1일 8시간 이상 이용',
    price: OptionDict.day10.amount,
    hourPrice: 28600,
    estimateDay: 10,
    estimateHour: 8,
    estimateMinHour: 8,

    description: [
      '하루 최소 8시간 기준으로, 한달에 총 <i class="blue">80시간</i> 이상 이용 가능합니다.',
      // '고객님이 선택하신 상품의 시간당 단가는 <i class="blue">28,600원</i> 입니다.',
      '해당 상품의 드라이버 풀은 <i class="blue">5명</i> 입니다.',
      '<i class="blue">5명</i>의 드라이버를 추천드립니다.'
    ]
  },
  {
    title: '14일 상품',
    category: OptionDict.day14.category,
    value: 'day14',
    // subtitle: '월 112시간 1일 최소 8시간 이상 이용',
    subtitle: '1일 8시간 이상 이용',
    price: OptionDict.day14.amount,
    hourPrice: 26400,
    estimateDay: 14,
    estimateHour: 8,
    estimateMinHour: 8,
    description: [
      '하루 최소 8시간 기준으로, 한달에 총 <i class="blue">112시간</i> 이상 이용 가능합니다.',
      // '고객님이 선택하신 상품의 시간당 단가는 <i class="blue">26,400원</i> 입니다.',
      '해당 상품의 드라이버 풀은 <i class="blue">3명</i> 입니다.',
      '<i class="blue">10명</i>의 드라이버를 추천드립니다.'
    ]
  },
  {
    title: '20일 상품',
    category: OptionDict.day20.category,
    value: 'day20',
    // subtitle: '월 160시간 1일 최소 8시간 이상 이용',
    subtitle: '1일 8시간 이상 이용',
    price: OptionDict.day20.amount,
    hourPrice: 24750,
    estimateDay: 20,
    estimateHour: 8,
    estimateMinHour: 8,
    description: [
      '하루 최소 8시간 기준으로, 한달에 총 <i class="blue">160시간</i> 이상 이용 가능합니다.',
      // '고객님이 선택하신 상품의 시간당 단가는 <i class="blue">24,750원</i> 입니다.',
      '해당 상품의 드라이버 풀은 <i class="blue">3명</i> 입니다.',
      '<i class="blue">10명</i>의 드라이버를 추천드립니다.'
    ]
  },
  {
    title: '30일 상품',
    category: OptionDict.day30.category,
    value: 'day30',
    // subtitle: '월 240시간 1일 최소 8시간 이상 이용',
    subtitle: '1일 8시간 이상 이용',
    price: OptionDict.day30.amount,
    hourPrice: 23100,
    estimateDay: 30,
    estimateHour: 8,
    estimateMinHour: 8,
    description: [
      '하루 최소 8시간 기준으로, 한달에 총 <i class="blue">240시간</i> 이상 이용 가능합니다.',
      // '고객님이 선택하신 상품의 시간당 단가는 <i class="blue">23,100원</i> 입니다.',
      '해당 상품의 드라이버 풀은 <i class="blue">3명</i> 입니다.',
      '<i class="blue">10명</i>의 드라이버를 추천드립니다.'
    ]
  }
  // ,
  // {
  //   title: '30일 상품',
  //   category: OptionDict.day30_2.category,
  //   value: 'day30_2',
  //   // subtitle: '월 360시간 1일 최소 12시간 이상 이용',
  //   subtitle: '1일 12시간 이상 이용',
  //   price: OptionDict.day30_2.amount,
  //   hourPrice: 19250,
  //   estimateDay: 30,
  //   estimateHour: 12,
  //   estimateMinHour: 12,
  //   description: [
  //     '하루 최소 8시간 기준으로, 한달에 총 <i class="blue">360시간</i> 이상 이용 가능합니다.',
  //     '고객님이 선택하신 상품의 시간당 단가는 <i class="blue">19,250원</i> 입니다.',
  //     '해당 상품의 드라이버 풀은 <i class="blue">3명</i> 입니다.',
  //     '<i class="blue">10명</i>의 드라이버를 추천드립니다.'
  //   ]
  // },
]

export const UsageTimeOptionsAnnual = [
  {
    title: '100시간 회원권',
    category: OptionDict.annual_1year_100h.category,
    value: 'annual_1year_100h',
    subtitle: '연 100시간 1일 최소 8시간 이상 이용',
    price: OptionDict.annual_1year_100h.amount,
    hourPrice: 29000,
    estimateDay: 1,
    estimateHour: 100,
    estimateMinHour: 8,
    description: [
      '하루 최소 8시간 기준으로, 연 총 <i class="blue">100시간</i> 이상 이용 가능합니다.',
      // '고객님이 선택하신 상품의 시간당 단가는 <i class="blue">29,000원</i> 입니다.',
      // '해당 상품의 드라이버 풀은 <i class="blue">5명</i> 입니다.',
      // '<i class="blue">5명</i>의 드라이버를 추천드립니다.'
    ]
  },
  {
    title: '200시간 회원권',
    category: OptionDict.annual_1year_200h.category,
    value: 'annual_1year_200h',
    subtitle: '연 200시간 1일 최소 8시간 이상 이용',
    price: OptionDict.annual_1year_200h.amount,
    hourPrice: 28000,
    estimateDay: 1,
    estimateHour: 200,
    estimateMinHour: 8,
    description: [
      '하루 최소 8시간 기준으로, 연 총 <i class="blue">200시간</i> 이상 이용 가능합니다.',
      // '고객님이 선택하신 상품의 시간당 단가는 <i class="blue">28,000원</i> 입니다.',
      // '해당 상품의 드라이버 풀은 <i class="blue">5명</i> 입니다.',
      // '<i class="blue">5명</i>의 드라이버를 추천드립니다.'
    ]
  },
  {
    title: '300시간 회원권',
    category: OptionDict.annual_1year_300h.category,
    value: 'annual_1year_300h',
    subtitle: '연 300시간 1일 최소 8시간 이상 이용',
    price: OptionDict.annual_1year_300h.amount,
    hourPrice: 26000,
    estimateDay: 1,
    estimateHour: 300,
    estimateMinHour: 8,
    description: [
      '하루 최소 8시간 기준으로, 연 총 <i class="blue">300시간</i> 이상 이용 가능합니다.',
      // '고객님이 선택하신 상품의 시간당 단가는 <i class="blue">26,000원</i> 입니다.',
      // '해당 상품의 드라이버 풀은 <i class="blue">5명</i> 입니다.',
      // '<i class="blue">5명</i>의 드라이버를 추천드립니다.'
    ]
  },
  {
    title: '250시간 회원권',
    category: OptionDict.annual_1year_250h_pm.category,
    value: 'annual_1year_250h_pm',
    subtitle: '연 250시간 1일 최소 10시간 이상 이용[프로모션]',
    price: OptionDict.annual_1year_250h_pm.amount,
    hourPrice: 25000,
    estimateDay: 1,
    estimateHour: 250,
    estimateMinHour: 10,
    description: [
      '하루 최소 10시간 기준으로, 연 총 <i class="blue">250시간</i> 이상 이용 가능합니다.',
      // '고객님이 선택하신 상품의 시간당 단가는 <i class="blue">25,000원</i> 입니다.',
      // '해당 상품의 드라이버 풀은 <i class="blue">5명</i> 입니다.',
      // '<i class="blue">5명</i>의 드라이버를 추천드립니다.'
    ]
  },
  {
    title: '500시간 회원권',
    category: OptionDict.annual_1year_500h_pm.category,
    value: 'annual_1year_500h_pm',
    subtitle: '연 500시간 1일 최소 10시간 이상 이용[프로모션]',
    price: OptionDict.annual_1year_500h_pm.amount,
    hourPrice: 23000,
    estimateDay: 1,
    estimateHour: 500,
    estimateMinHour: 10,
    description: [
      '하루 최소 10시간 기준으로, 연 총 <i class="blue">500시간</i> 이상 이용 가능합니다.',
      // '고객님이 선택하신 상품의 시간당 단가는 <i class="blue">23,000원</i> 입니다.',
      // '해당 상품의 드라이버 풀은 <i class="blue">5명</i> 입니다.',
      // '<i class="blue">5명</i>의 드라이버를 추천드립니다.'
    ]
  },
]


// 채용
export const UsageTimeOptionsDriverDispatch = [
  {
    // title: '10시간 상품',
    title: '10시간 상품',
    category: OptionDict.day10.category,
    value: '5500',
    // subtitle: '월 80시간 1일 최소 8시간 이상 이용',
    // subtitle: '평일 주 5일 기준',
    // subtitle2: '평일 주 5일 기준 10시간 근무',
    subtitle: '평일 주 5일 기준',
    subtitle2: '평일 주 5일 기준 10시간 근무',
    price: OptionDict.day10.amount,
    hourPrice: 28600,
    estimateDay: 10,
    estimateHour: 8,
    estimateMinHour: 8,

    description: [
      '<i class="blue">월~금 10</i>시간 근로 상품입니다.',
      '주말 근무 미포함입니다. 이용시 별도요금이 발생됩니다.',
      // '<i class="blue">5명</i>의 드라이버를 추천드립니다.'
    ]
  },
  {
    // title: '10시간 상품',
    title: '8시간 상품',
    category: OptionDict.day14.category,
    value: '4500',
    // subtitle: '월 80시간 1일 최소 8시간 이상 이용',
    // subtitle: '평일 주 5일 기준',
    // subtitle2: '평일 주 5일 기준 10시간 근무',
    subtitle: '평일 주 5일 기준',
    subtitle2: '평일 주 5일 기준 8시간 근무',
    price: OptionDict.day14.amount,
    hourPrice: 28600,
    estimateDay: 10,
    estimateHour: 8,
    estimateMinHour: 8,

    description: [
      '<i class="blue">월~금 8</i>시간 근로 상품입니다.',
      '주말 근무 미포함입니다. 이용시 별도요금이 발생됩니다.',
      // '<i class="blue">5명</i>의 드라이버를 추천드립니다.'
    ]
  },


]

// 파견
export const UsageTimeOptionsDriverDispatchPagyun = [
  {
    title: '10시간 상품',
    // title: '추후협의',
    category: OptionDict.day10.category,
    value: '5500',
    // subtitle: '월 80시간 1일 최소 8시간 이상 이용',
    subtitle: '평일 주 5일 기준',
    subtitle2: '평일 주 5일 기준 10시간 근무',
    // subtitle: '근무조건',
    // subtitle2: '근무조건 추후 협의',
    price: OptionDict.day10.amount,
    hourPrice: 28600,
    estimateDay: 10,
    estimateHour: 8,
    estimateMinHour: 8,

    description: [
      '<i class="blue">월~금 10</i>시간 근로 상품입니다.',
      '주말 근무 미포함입니다. 이용시 별도요금이 발생됩니다.',
      // '<i class="blue">5명</i>의 드라이버를 추천드립니다.'
    ]
  },
  // {
  //   title: '8시간 상품',
  //   category: OptionDict.day14.category,
  //   value: '4500',
  //   // subtitle: '월 112시간 1일 최소 8시간 이상 이용',
  //   subtitle: '평일 주 5일 기준',
  //   subtitle2: '평일 주 5일 기준 8시간 근무',
  //   price: OptionDict.day14.amount,
  //   hourPrice: 26400,
  //   estimateDay: 14,
  //   estimateHour: 8,
  //   estimateMinHour: 8,
  //   description: [
  //     '<i class="blue">월~금 8</i>시간 근로 상품입니다.',
  //     '주말 근무 미포함입니다. 이용시 별도요금이 발생됩니다.',
  //     // '<i class="blue">5명</i>의 드라이버를 추천드립니다.'
  //   ]
  // },


]



//////////////// 옵션 ////////////////

/** 등록 가능 차량 **/
export const NumberOfVehiclesOptions = [
  {
    title: '2대',
    category: OptionDict.twoVehicles.category,
    value: 'twoVehicles',
    price: OptionDict.twoVehicles.amount,
    optionValue: 0x0001
  },
  {
    title: '3대',
    category: OptionDict.threeVehicles.category,
    value: 'threeVehicles',
    price: OptionDict.threeVehicles.amount,
    optionValue: 0x0002
  }
]

// /** 역할 구분 **/
// export const RoleOfVehiclesOptions = [
//   {
//     title: '수행기사',
//     category: OptionDict.driver.category,
//     value: 'driver',
//     price: OptionDict.driver.amount,
//     optionValue: 0x10000
//   },
//   {
//     title: '수행기사+사택기사',
//     category: OptionDict.business.category,
//     value: 'business',
//     price: OptionDict.business.amount,
//     optionValue: 0x20000
//   }
// ]


export const RoleOfVehiclesOptions = (subscription, usageTime) => {
  let retData = [
    {
      title: '수행기사',
      category: OptionDict.driver.category,
      value: 'driver',
      price: OptionDict.driver.amount,
      optionValue: 0x10000
    }]

    if (subscription === 'monthly') {
      if (usageTime === 'day10'){
        retData.push({
          title: '수행기사+사택기사',
          category: OptionDict.business.category,
          value: 'business',
          price: OptionDict.business_10.amount,
          optionValue: 0x20000
        })
      }
      else if (usageTime === 'day14'){
        retData.push({
          title: '수행기사+사택기사',
          category: OptionDict.business.category,
          value: 'business',
          price: OptionDict.business_14.amount,
          optionValue: 0x20000
        })
      }
      else if (usageTime === 'day20'){
        retData.push({
          title: '수행기사+사택기사',
          category: OptionDict.business.category,
          value: 'business',
          price: OptionDict.business_20.amount,
          optionValue: 0x20000
        })
      }
      else {
        retData.push({
          title: '수행기사+사택기사',
          category: OptionDict.business.category,
          value: 'business',
          price: OptionDict.business_30.amount,
          optionValue: 0x20000
        })
      }
    }
    else {
      retData.push({
        title: '수행기사+사택기사',
        category: OptionDict.business.category,
        value: 'business',
        price: OptionDict.business.amount,
        optionValue: 0x20000
      })
    }

  return retData
   
  
} 



export const CarAmountOptions = [
  {
    title: '2억이하',
    category: OptionDict.twomilliondown.category,
    value: 'twomilliondown',
    price: OptionDict.twomilliondown.amount,
    optionValue: 0x0010
  },
  {
    title: '하이리무진,특장차',
    category: OptionDict.twomillionup.category,
    value: 'twomillionup',
    price: OptionDict.twomillionup.amount,
    optionValue: 0x0020
  }
]

export const InsuranceOptions = [
  {
    title: '기본보험',
    category: OptionDict.insubasic.category,
    value: 'insubasic',
    // price: OptionDict.insubasic.amount,
    discount: 0,
    optionValue: 0x0100
  },
  {
    title: '고객보험',
    category: OptionDict.insucustomer.category,
    value: 'insucustomer',
    // price: OptionDict.insucustomer.amount,
    discount: 100000,
    optionValue: 0x0200
  }
]

export const PassengerPersonOptions = [
  {
    title: '2명',
    category: OptionDict.persontwo.category,
    value: 'persontwo',
    price: OptionDict.persontwo.amount,
    optionValue: 0x1000
  },
  {
    title: '3명~4명',
    category: OptionDict.personfour.category,
    value: 'personfour',
    price: OptionDict.personfour.amount,
    optionValue: 0x2000
  }
]

export const VehicleOptionDescription = [
  '월간 모시러 서비스 이용 대상 차량은 <i class="blue">1대</i> 입니다.',
  '드라이버를 <i class="blue">수행기사 또는 사택기사</i> 서비스로 이용 가능합니다.',
  '요구되는 드라이버의 면허는 <i class="blue">1종 대형</i> 입니다.'
]

/** 계약기간 **/
export const ContractPeriodOptions = [
  {
    title: '1개월',
    category: OptionDict.month1.category,
    value: 'month1',
    numberValue: 1,
    discount: OptionDict.month1.discount,
    description: '기본 비용 / 할인 없음'
  },
  {
    title: '4개월',
    category: OptionDict.month4.category,
    value: 'month4',
    numberValue: 4,
    discount: OptionDict.month4.discount,
    description: '월 -75,000원 절감 / 총 -300,000원 할인'
  },
  {
    title: '12개월',
    category: OptionDict.month12.category,
    value: 'month12',
    numberValue: 12,
    discount: OptionDict.month12.discount,
    description: '월 -41,666원 절감, 총 -500,000원 할인'
  },
]


/** 이용 목적 **/
export const PurposeOfContractOptions = [
  {
    title: '비즈니스',
    category: 'purposeOfContract',
    value: 'business',
  },
  {
    title: '골프',
    category: 'purposeOfContract',
    value: 'golf',
  },
  {
    title: '키즈/실버',
    category: 'purposeOfContract',
    value: 'kids-silver',
  },
]

/** 탑승자 **/
export const PassengerOptions = [
  {
    title: '회사 대표님 or 임원',
    category: 'passenger',
    value: 'ceo',
  },
  {
    title: '가족 + 임직원',
    category: 'passenger',
    value: 'family',
  },
  {
    title: 'VIP 고객',
    category: 'passenger',
    value: 'vip',
  },
  {
    title: '기타',
    category: 'passenger',
    value: 'etc',
  },
]

/** 알게된 경로 **/
export const RouteOptions = [
  {
    title: '인스타그램',
    category: 'route',
    value: 'route_instagram',
  },
  {
    title: '페이스북',
    category: 'route',
    value: 'route_facebook',
  },
  {
    title: '지인소개',
    category: 'route',
    value: 'route_friend',
  },
  {
    title: '모시러 홈페이지',
    category: 'route',
    value: 'route_homepage',
  },
  {
    title: '기타',
    category: 'route',
    value: 'route_etc',
  },
]





/** 견적 확인 description **/
export const ConfirmEstimateDescription = [
  '본 견적 내 항목은 모두 VAT 포함입니다.',
  '실 탑승자 4인 까지를 기준으로 한 견적이며, 5인 이상 이용이 필요하신 경우 컨시어지로 문의 바랍니다.',
  '기본 이용시간 외 추가 이용도 가능하며, 시간당 단가가 반영되어 추가 청구됩니다.',
  '본 견적서 내 포함되지 않은 항목이라도 실제 서비스 이용에 따라 할증 요금이 사전 안내 후 청구될 수 있습니다.',
  '본 견적서의 내용은 2023년 9월 1일까지 유효합니다.'
]




/** 고객 유형 **/
export const UserTypeOptions = [
  {
    title: '개인',
    category: 'userType',
    value: 'personal'
  },
  {
    title: '법인/개인 사업자',
    category: 'userType',
    value: 'corporate'
  }
]

/** 결제 수단 **/
export const PaymentMethodOptions = [
  {
    title: '카드',
    category: 'paymentMethod',
    value: 'card'
  },
  {
    title: '현금',
    category: 'paymentMethod',
    value: 'cash'
  }
]


// 채용 의뢰 초안
export const GenderOption = [
  {
    title: '남성',
    category: 'gender',
    value: 'men'
  },
  {
    title: '여성',
    category: 'gender',
    value: 'woman'
  },
  {
    title: '무관',
    category: 'gender',
    value: 'both'
  },

]
export const DriverSmokingStatusOption = [
  {
    title: '비흡연',
    category: 'driverSmokingStatus',
    value: 'nosmoking'
  },
  {
    title: '전자담배허용',
    category: 'driverSmokingStatus',
    value: 'smoking'
  },
  {
    title: '상관없음',
    category: 'driverSmokingStatus',
    value: 'both'
  }
]
export const DriverParkingSupportOption = [
  {
    title: '가능',
    category: 'driverParkingSupport',
    value: 'possible'
  },
  {
    title: '불가능',
    category: 'driverParkingSupport',
    value: 'impossible'
  }
]
export const CommutingVehicleProvidedOption = [
  {
    title: '가능',
    category: 'commutingVehicleProvided',
    value: 'possible'
  },
  {
    title: '불가능',
    category: 'commutingVehicleProvided',
    value: 'impossible'
  }
]
export const ForeignLanguageProficiencyOption = [
  {
    title: '불필요',
    category: 'foreignLanguageProficiency',
    value: 'impossible'
  },
  {
    title: '필요',
    category: 'foreignLanguageProficiency',
    value: 'possible'
  },

]
export const DriverWaitingRoomAvaliableOption = [
  {
    title: '있음',
    category: 'driverWaitingRoomAvailable',
    value: 'possible'
  },
  {
    title: '없음',
    category: 'driverWaitingRoomAvailable',
    value: 'impossible'
  }
]
export const EducationalBackgroundOption = [
  {
    title: '무관',
    category: 'educationalBackground',
    value: 'both'
  },
  {
    title: '초대졸',
    category: 'educationalBackground',
    value: 'associatedegree'
  },
  {
    title: '대졸',
    category: 'educationalBackground',
    value: 'bachelordegree'
  }

]