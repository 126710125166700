import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PageTitle from '../../atoms/PageTitle';
import { getValueByTarget, VehiclesOptionCount } from '../../../utils/parse';
import {
    BookingProducts,
    ConfirmEstimateDescription,
    ContractPeriodOptions,
    NumberOfVehiclesOptions,
    PassengerOptions,
    PurposeOfContractOptions,
    RoleOfVehiclesOptions,
    UsageTimeOptions,
    CarAmountOptions,
    InsuranceOptions,
    PassengerPersonOptions,
    UsageTimeOptionsAnnual,
    UsageTimeOptionsMonthly,

} from '../../../utils/EstimateFormData';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListBox from '../../atoms/ListBox';

import useCheckPC from '../../../hooks/useCheckPC';
import ListWithBullet from '../../atoms/ListWithBullet';
import { transFormWon } from '../../../utils/numbers';
import { getEstimateCookieDataAndDecrypt, isChkAddressSeoul } from '../../../lib/Util';

import moment from 'moment';
import vutler_dojang from '../../../styles/img/vutler_dojang.png'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

/**
 * 견적 확인 페이지
 * name : confirmEstimate
 * */

const EstimateDownload = () => {
    const navigate = useNavigate();
    const { isPC } = useCheckPC();
    const { BookingOption, ContractMonthArray } = useSelector((state) => state);
    const [loading, setLoading] = useState(false)
    const [firstProcess, setFirstProcess] = useState(false)
    const [UserName, SetUserName] = useState('')

    const [ConfirmEstimateDescription2, SetConfirmEstimateDescription2] = useState([
        '본 견적 내 항목은 모두 VAT 포함입니다.',
        '실 탑승자 4인 까지를 기준으로 한 견적이며, 5인 이상 이용이 필요하신 경우 컨시어지로 문의 바랍니다.',
        '기본 이용시간 외 추가 이용도 가능하며, 시간당 단가가 반영되어 추가 청구됩니다.',
        '본 견적서 내 포함되지 않은 항목이라도 실제 서비스 이용에 따라 할증 요금이 사전 안내 후 청구될 수 있습니다.',
    ])

    /** 상품명 **/
    const [subscription, setSubscription] = useState([]);
    /** 이용 시간**/
    const [usageTime, setUsageTime] = useState([]);
    /** 차량 옵션 **/
    const [vehicleOption, setVehicleOption] = useState([]);
    /** 계약 조건 **/
    const [periodOfContract, setPeriodOfContract] = useState([]);
    /** 이용 지역 **/
    const [serviceArea, setServiceArea] = useState([])
    /** 이용 정보 **/
    const [contractTerms2, setContractTerm2] = useState([]);


    const sleep = (time) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve()
            }, time);
        })
    }


    const downloadPdfData = async () => {
        let canvas = await html2canvas(document.querySelector('#estimate_form'), {
            width: 635,
            // height:891
            height: 1500
        })
        //200

        let imgData = canvas.toDataURL('image/png')

        let pdf = new jsPDF('p', 'mm', 'a3')
        pdf.addImage(imgData, 'PNG', 46, 10, 190, 430, '', 'MEDIUM')
        // pdf.addImage(imgData, 'PNG', 10, 10, 140, 210)
        pdf.save(`견적서_${moment().format('yyyyMMDDHHssmm')}`)
        return true
    }


    useEffect(() => {

        let info = getEstimateCookieDataAndDecrypt()
        SetUserName(info.UserName)
        

        if (BookingOption.subscription === 'annual') {
            
        setSubscription([
            {
                title: `${getValueByTarget(BookingProducts,BookingOption.subscription,'title')} ${getValueByTarget(UsageTimeOptionsAnnual,BookingOption.usageTime,'title')}`,
                price: getValueByTarget(BookingProducts,BookingOption.subscription,'price') + getValueByTarget(UsageTimeOptionsAnnual,BookingOption.usageTime,'price')
            }])
        }
        else {

            setSubscription([
                {
                    title: `${getValueByTarget(BookingProducts,BookingOption.subscription,'title')} ${getValueByTarget(UsageTimeOptions,BookingOption.usageTime,'title')}`,
                    price: getValueByTarget(BookingProducts,BookingOption.subscription,'price') + getValueByTarget(UsageTimeOptions,BookingOption.usageTime,'price')
                }])
            
        }

        /** 차량 옵션 **/
        const updatedVehicleOption = [
            {
                title: `등록 차량 대수: ${getValueByTarget(
                    NumberOfVehiclesOptions,
                    BookingOption.vehicleOption.numberOfVehicles,
                    'title'
                )}`,
                price: getValueByTarget(
                    NumberOfVehiclesOptions,
                    BookingOption.vehicleOption.numberOfVehicles,
                    'price'
                )
            },
            {
                title: `차량 가격: ${getValueByTarget(
                    CarAmountOptions,
                    BookingOption.vehicleOption.carAmount,
                    'title'
                )}`,
                price: getValueByTarget(
                    CarAmountOptions,
                    BookingOption.vehicleOption.carAmount,
                    'price'
                )
            },
            {
                title: `보험: ${getValueByTarget(
                    InsuranceOptions,
                    BookingOption.vehicleOption.insurance,
                    'title'
                )}`,
                discount: getValueByTarget(
                    InsuranceOptions,
                    BookingOption.vehicleOption.insurance,
                    'discount'
                )
            },
            // {
            //     title: `탑승 고객: ${getValueByTarget(
            //         PassengerPersonOptions,
            //         BookingOption.vehicleOption.passengerPerson,
            //         'title'
            //     )}`,
            //     price: getValueByTarget(
            //         PassengerPersonOptions,
            //         BookingOption.vehicleOption.passengerPerson,
            //         'price'
            //     )
            // },
            {
                title: `서비스: ${getValueByTarget(
                    RoleOfVehiclesOptions(BookingOption.subscription, BookingOption.usageTime),
                    BookingOption.vehicleOption.roleOfVehicles,
                    'title'
                )}`,
                price: getValueByTarget(
                    RoleOfVehiclesOptions(BookingOption.subscription, BookingOption.usageTime),
                    BookingOption.vehicleOption.roleOfVehicles,
                    'price'
                )
            }]
        setVehicleOption(updatedVehicleOption)

        /** 계약 조건 **/
        const updatedPeriodOfContract = [
            {
                title: `계약 기간: ${getValueByTarget(
                    ContractMonthArray.select,
                    BookingOption.contractTerms1.periodOfContract,
                    'title'
                )}`,
                discount: getValueByTarget(
                    ContractMonthArray.select,
                    BookingOption.contractTerms1.periodOfContract,
                    'discount'
                )
            }
        ]
        setPeriodOfContract(updatedPeriodOfContract)

        /** 이용 지역 **/
        const updatedServiceArea = [
            {
                title: `차고지: 
          ${BookingOption?.contractTerms1?.serviceArea?.departure?.address1} 
          ${BookingOption?.contractTerms1?.serviceArea?.departure?.address2}`
            }
        ].filter(Boolean);
        setServiceArea(updatedServiceArea)

        /** 이용 정보 **/
        const updatedContractTerms2 = [
            { title: `이용 목적: ${getValueByTarget(PurposeOfContractOptions, BookingOption?.contractTerms2?.purposeOfContract, 'title')}` },
            { title: `탑승자: ${getValueByTarget(PassengerOptions, BookingOption.contractTerms2.passenger, 'title')}` },
            { title: `차량 번호 1: ${BookingOption.contractTerms2.vehicleName1}` },
            { title: `차량 번호 2: ${BookingOption.contractTerms2.vehicleName2}` },

        ]

        if (VehiclesOptionCount(BookingOption?.vehicleOption?.numberOfVehicles) === 3) {
            updatedContractTerms2.push({ title: `차량 번호 3: ${BookingOption.contractTerms2.vehicleName3}` })
        }

        setContractTerm2(updatedContractTerms2)


        const seoulResult = isChkAddressSeoul(BookingOption?.contractTerms1?.serviceArea?.departure?.address1, BookingOption?.contractTerms1?.serviceArea?.destination?.address1)
        const addedArray = [];
        if (!seoulResult) addedArray.push('예상 출발지/종료지가 서울 이외일 경우, 지역할증이 부과될 수 있습니다.')
        addedArray.push(`본 견적서의 내용은 ${moment().add(1, 'months').format('yyyy년 MM월 DD일')}까지 유효합니다.`)


        SetConfirmEstimateDescription2([
            ...ConfirmEstimateDescription2,
            ...addedArray
        ])
        setLoading(true)

        const ProcessFunc = async () => {
            if (!firstProcess) {
                await sleep(2000)
                await downloadPdfData()
                await sleep(1000)

                window.close()
            }
            setFirstProcess(true)
        }
        ProcessFunc()

    }, [BookingOption])



    return (
        <>
            <div style={{ width: '100%', height: '100vh', position: 'absolute', backgroundColor: 'white', textAlign: 'center', fontSize: '1.2em', paddingTop: '30px', zIndex: '10' }}>
                <div>현재 견적서 다운로드 처리를 위해 작업중입니다.</div>
                <div>잠시만 기다려주십시오.</div>
                <div>이 창을 닫지 말아주세요.</div>
                <div className="loader loader-9"></div>
            </div>
            <div id="estimate_form" style={{
                padding: '30px 20px',
                width: '635px',
                height: '891px'
            }}>
                <div className={classNames('booking-wrap2')}>
                    <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '40px' }}>월간모시러 견적서</div>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ fontSize: '18px' }}>{moment().format('yyyy년 MM월 DD일')}</div>
                        <div style={{ textAlign: 'right' }}>
                            <span style={{ display: 'block', padding: '5px 0', fontSize: '18px' }}>{UserName} 고객님</span>
                            <span style={{ display: 'block', padding: '5px 0', fontSize: '18px' }}>아래와 같이 견적서를 발송합니다.</span>
                        </div>
                    </div>

                    <div className={classNames('confirm-booking-wrap2')}>
                        <div className={classNames('confirm-booking-content2')}>
                            {/** title **/}
                            {(BookingOption.subscription === 'monthly') && <ListBox
                                title={`월간 모시러`}
                            />}

                            {(BookingOption.subscription === 'annual') && <ListBox
                                title={`연간 모시러`}
                            />}


                            {/** 상품명 **/}
                            <ListBox
                                title={'상품명'}
                                listItem={subscription}
                            />

                            {/** 옵션 **/}
                            <ListBox
                                title={'옵션'}
                                listItem={vehicleOption}
                            />

                            {/** 계약 조건 **/}
                            {(BookingOption.subscription === 'monthly') && <ListBox
                                title={'계약 조건'}
                                listItem={periodOfContract}
                            />}


                            {/** 이용하실 지역 **/}
                            <ListBox
                                title={'이용하실 지역'}
                                listItem={serviceArea}
                            />

                            {/** 이용 정보 **/}
                            {/* <ListBox
                                title={'이용 정보'}
                                listItem={contractTerms2}
                            /> */}

                            {/* <TotalAmount styleType={'secondary'} /> */}
                            <div className={classNames('total-amount')}>
                                <span className={classNames('grey-2', 'title-mid')}>견적 총 금액</span>
                                <span className={classNames('title-mid')}>{transFormWon(BookingOption?.totalAmount)}</span>
                            </div>
                        </div>

                        <div className={classNames('box-light-grey-1 footer-contents2')}>
                            <ul className={classNames('list-style')}>
                                {ConfirmEstimateDescription2.map((item, index) => (
                                    <li key={index}>
                                        <span className={classNames('grey-2')} dangerouslySetInnerHTML={{ __html: item }}>
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginBottom: '100px' }}>
                            <div style={{ fontSize: '18px' }}>
                                <span style={{ display: 'block', padding: '5px 0', fontSize: '18px', fontWeight: 'bold' }}>주식회사 버틀러</span>
                                <span style={{ display: 'block', padding: '5px 0', fontSize: '18px' }}>서울 강남구 역삼로 160. 5층</span>
                            </div>
                            <div style={{ fontSize: '18px', textAlign: 'right' }}>
                                <span style={{ display: 'block', padding: '5px 0', fontSize: '18px' }}>463-87-00162</span>
                                <span style={{ display: 'block', padding: '5px 0', fontSize: '18px' }}>이근우</span>
                            </div>
                            <div style={{ textAlign: 'left' }}>
                                <span style={{ display: 'block', fontSize: '18px' }}><img src={vutler_dojang} style={{ width: '80%' }} alt='' /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default EstimateDownload;