import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Input from '../atoms/Input';
import RadioGroup from '../atoms/Radio/RadioGroup';
import Radio from '../atoms/Radio';
import Select2 from '../atoms/Select2';
import { handleTimeChange, hourList2, minuteList2, handleTimeChange2 } from '../../utils/numbers';
import { setEndTime } from '../../reducers/reservation';
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';

const TimeInput2 = (
    {
        start = false,
        end = false,
        setError,
        register,
        setValue,
        getValues,
        startHourRegister,
        startMinuteRegister,
        addHourRegister,
        dayTimeRegister,
        TimeRegister,
        HoursRegister,
        dayTimeError,
        TimeError,
        HoursError,
        errorMessage, 
        ReserveFreeFlag,
        MatchingWaitingTimeHour=4,
    }) => {


    const [selectedStartHour, setSelectedStartHour] = useState('00')
    const [selectedStartMinute, setSelectedStartMinute] = useState('00')
    const [selectedHour, setSelectedHour] = useState(1)
    const [hourList, setHourList] = useState([])
    const dispatch = useDispatch();
    const contractOneDayAvaliableHour = useSelector((state) => state.Reservation.contractOnedayHour);
    const reduxEndTime = useSelector((state) => state.Reservation.usageTime.endTime);

    const [customHourList, setCustomHourList] = useState([])

    const convertTimeTmp = (time) => {
        let tomorrow = ''
        if (time >= 24) tomorrow = '익일'
        return (time%24) <= 9 ? tomorrow + '0' + (time%24) : tomorrow +  (time%24)+'';
    }

    useEffect(() => {
        
        // if (ReserveFreeFlag) {
        //     let now = moment()
        //     let fourHoursLater = moment(now).add(MatchingWaitingTimeHour, 'hours');
        //     let tmpArr =[]
        //     let hourData = Number(fourHoursLater.format('H'))
        //     /* 13시에 예약한다 했을 때 -> 17 */
        //     for (let i = 0; i < 24; i++) {
        //         tmpArr.push(convertTimeTmp(hourData++))
        //     }

        //     console.log(tmpArr)
        //     setCustomHourList(tmpArr)

        //     let isTomorrow = !fourHoursLater.isSame(now, 'day');

        //     // 24시부터 % 연산하여 0, 1, 2, 3 이런식으로 표현, 앞에 
            
        // }

    }, [])

    /*
    // 24시간 예약 대상자라면
            if (ReserveFreeFlag) {
              let now = moment()
              let fourHoursLater = moment(now).add(MatchingWaitingTimeHour, 'hours');

              let isTomorrow = !fourHoursLater.isSame(now, 'day');
              // 4시간 후가 익일인가?
              if (isTomorrow) {
                // 오늘 기준 다음날로 넘어가야함
                return (moment(date.date).format('yyyy-MM-DD') < fourHoursLater.format('yyyy-MM-DD'))
              }
              // 익일이 아니면
              else {
                // 오늘도 체크 가능해야함.
                return moment(date.date).format('yyyy-MM-DD') <= now.add(-1,'days').format('yyyy-MM-DD')
              }
            }
    
    */


    useEffect(() => {
        //console.log('init2')
        if (contractOneDayAvaliableHour !== undefined && contractOneDayAvaliableHour !== null) {
            let tmpArr = [];
            for (let i = contractOneDayAvaliableHour; i <= 24; i++) {
                tmpArr.push(i)
            }
            setHourList(tmpArr)
            setSelectedHour(contractOneDayAvaliableHour)
        }
    }, [contractOneDayAvaliableHour])


    // 종료시간 업데이트를 위한 useEffect
    useEffect(() => {
        //console.log('init3')
        let tmpStr1 = moment(`2023-09-11 ${selectedStartHour}:${selectedStartMinute}`)
        let tmpStr2 = moment(`2023-09-11 ${selectedStartHour}:${selectedStartMinute}`).add(selectedHour, 'hours')
        const isNextDay = tmpStr1.day() !== tmpStr2.day();

        dispatch(setEndTime(tmpStr2.format('HH:mm'), isNextDay))
        setValue('endTime', tmpStr2.format('HH:mm'))
    }, [selectedStartHour, selectedStartMinute, selectedHour])



    return (
        <>
            <div className={classNames('time-input')}>
                {start && (
                    <>
                        {ReserveFreeFlag ? (
                            <>
                                <Select2
                                    value={getValues('startHour')}
                                    classes={classNames('select-small')}
                                    selectedOption={selectedStartHour}
                                    options={hourList2}
                                    onOptionChange={hour => {
                                        setValue(startHourRegister, hour)
                                        setSelectedStartHour(hour)
                                    }}
                                />
                                <span>시</span>

                            </>
                        ) : (
                            <>
                                <Select2
                                    value={getValues('startHour')}
                                    classes={classNames('select-small')}
                                    selectedOption={selectedStartHour}
                                    options={hourList2}
                                    onOptionChange={hour => {
                                        setValue(startHourRegister, hour)
                                        setSelectedStartHour(hour)
                                    }}
                                />
                                <span>시</span>

                            </>
                        )}
                        <Select2
                            value={getValues('startMinute')}
                            classes={classNames('select-small')}
                            selectedOption={selectedStartMinute}
                            options={minuteList2}
                            onOptionChange={minute => {
                                setValue(startMinuteRegister, minute)
                                setSelectedStartMinute(minute)

                            }}
                        />
                        <span>분 부터</span>
                        <Select2
                            classes={classNames('select-small')}
                            selectedOption={selectedHour}
                            options={hourList}
                            onOptionChange={hour => {
                                setValue(HoursRegister, hour)
                                setSelectedHour(hour)

                            }}
                        />
                        <span>시간</span>

                    </>
                )}
                {end && (
                    <>
                        {reduxEndTime.nextDayFlag ? '익일' : ''}
                        <Input
                            classes={'input'}
                            style={{ width: '100px' }}
                            type={'text'}
                            {...register(TimeRegister)}
                            maxLength={5}
                            required
                            error={TimeError}
                            placeholder={'-- : --'}

                            onChange={(e) => {
                                handleTimeChange2(e, setValue, TimeRegister)
                            }}
                            disabled
                        />
                        <span>까지</span>
                    </>
                )}
            </div>
            {(dayTimeError || TimeError || HoursError) && (
                <span className={classNames('error')}>
                    {errorMessage}
                </span>
            )}
        </>
    )
}

export default TimeInput2