import React from 'react';
import classNames from 'classnames';
import {transFormWon} from '../../utils/numbers';
import useCheckPC from '../../hooks/useCheckPC';
import pdf_download from '../../styles/img/pdf_download.svg'
import Button from './Button';

const ListBoxWithIcon = (
  {
   title,
   listItem,
   downloadFlag,
   url
  }) => {
  const { isPC } = useCheckPC();
  return(
    <div className={classNames('list-box')} style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding:'10px 0px'}}>
      {title && (
        <span className={classNames(listItem ? 'grey-3 txt-regular' : 'title-mid bold')}>
          {title}
        </span>
      )}
      {downloadFlag && (
        <span className={classNames(isPC ? 'txt-large' : 'txt-regular', 'bold')}>
          <Button 
            styleType='icon' 
            style={{backgroundColor:'white', padding:'5px 5px'}}
            onClick={()=>{
              window.open(url, '_blank')
            }}
            >
            <img src={pdf_download} style={{width:'40px', height:'40px'}} />
          </Button>
          
        </span>
      )}
    </div>
  )
}

export default ListBoxWithIcon