import React, { useEffect, useState, useMemo, useRef } from 'react';
import classNames from 'classnames';
import PageTitle from '../../atoms/PageTitle';
import {
	BookingProducts,
	ContractPeriodOptions,
	PurposeOfContractOptions,
	UsageTimeOptionsAnnual,
	UsageTimeOptionsMonthly,
	UserTypeOptions,
	DriverDispatchProducts,
	WeekendUsageOptions,
	RecruitPurposeOption,
	MonthlyMosilerOption,
	UsageTimeOptionsDriverDispatch,
	GenderOption,
	DriverSmokingStatusOption,
	DriverParkingSupportOption,
	CommutingVehicleProvidedOption,
	ForeignLanguageProficiencyOption,
	DriverWaitingRoomAvaliableOption,
	EducationalBackgroundOption

} from '../../../utils/EstimateFormData';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import Radio from '../../atoms/Radio';
import BookingItem from '../../molecules/BookingItem';
import Input from '../../atoms/Input';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import Button from '../../atoms/Button';
import { URLS } from '../../../utils/urls';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDriverDispatchOptionContext } from '../../organisms/DriverDispatchOptionContext';
import { getValueByTarget } from '../../../utils/parse';
import { useForm } from 'react-hook-form';
import { updateUserInformation } from '../../../reducers/bookingEstimation';
import AddressInput from '../../molecules/AddressInput';
import Icon from '../../atoms/Icon';
import { formErrorMessage } from '../../../utils/alertMessage';
import { SetMmEstimateHistory, setSheetContractResponse } from '../../../lib/EstimateApi';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getDdEstimateCookieDataAndDecrypt, getEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import { updateDriverDispatchImage, updateDriverDispatchUserInformation } from '../../../reducers/driverDispatchEstimation';
import AddressInput2 from '../../molecules/AddressInput2';
import { uploadFile } from '../../../lib/MsAzureApi';
import { closeLoading, openLoading } from '../../../reducers/loadingRedux';
import { SendSlackDdContracting } from '../../../lib/SlackApi';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, ESTIMATE_TYPE_DRIVER_DISPATCH, PAGE } from '../../../utils/constant';

/**
 * 계약서 확인 > 고객 정보 페이지
 * name : userInformation
 * */

const UserInformation = () => {
	const inputRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { selectedOptions, setSelectedOptions } = useDriverDispatchOptionContext();
	const [description, setDescription] = useState([]);
	const [file, setFile] = useState(selectedOptions.userInformation.user.businessRegistration ?? null);
	const isExistsImages = useMemo(() => file?.length > 0, [file?.length]);
	const [userType, setUserType] = useState(selectedOptions.userInformation.userType)
	const { DriverDispatchOption } = useSelector((state) => state);
	const [fileManage, setFileManage] = useState(null)
	const [pageTitleDesc, setPageTitleDesc] = useState({
		title: '',
		subtitle: '',
		desc1: '',
		desc2: '',
		desc3: '',
		desc4: '',
	})

	useEffect(() => {

		if (DriverDispatchOption?.subscription === 'recruit') {
			setPageTitleDesc({
				title: '계약 정보',
				subtitle: '계약 정보를 입력하세요.',
				desc1: '계약자 이름',
				desc2: '계약자 주소',
				desc3: '',
				desc4: '',

			})
		}
		else {
			setPageTitleDesc({
				title: '파견 의뢰 정보',
				subtitle: '요청자의 정보를 입력하세요.',
				desc1: '요청자 이름',
				desc2: '요청자 주소',
				desc3: '',
				desc4: '',
			})
		}

		const updatedDescription = [];
		updatedDescription.push(
			`고객 유형은 <i class='blue'>
      ${getValueByTarget(
				UserTypeOptions,
				selectedOptions.userInformation.userType,
				'title'
			)}</i> 입니다.`
		);
		if (userType !== 'personal') {
			updatedDescription.push(
				`성함은 <i class='blue'>${selectedOptions?.userInformation?.user?.name ?? '미입력'}</i> 입니다.`,
				`회사명은 <i class='blue'>${selectedOptions?.userInformation?.user?.companyName ?? '미입력'}</i> 입니다.`,
				`사업장의 주소는 <i class='blue'>${selectedOptions?.userInformation?.user?.businessAddress?.address1 ?? '미입력'} ${selectedOptions?.userInformation?.user?.businessAddress?.address2 ?? '미입력'} </i> 입니다.`,
				`사업자 등록번호는 <i class='blue'>${selectedOptions?.userInformation?.user?.businessRegistrationId ?? '미입력'}</i> 입니다.`,
				`사업자 등록증이 ${file ? '첨부 되었습니다.' : '첨부되지 않았습니다.'}`
			);
		}
		else {
			updatedDescription.push(
				`성함은<i class='blue'> ${selectedOptions?.userInformation?.user?.name ?? '미입력'}</i> 입니다.`,
				`고객님의 주소는 <i class='blue'>${selectedOptions?.userInformation?.user?.personalAddress ?? '미입력'} ${selectedOptions?.userInformation?.user?.personalAddressDetail ?? '미입력'} </i> 입니다.`,
			)
		}
		setDescription(updatedDescription)
	}, [
		selectedOptions.userInformation.userType,
		selectedOptions.userInformation.user.name,
		selectedOptions.userInformation.user.businessAddress.address1,
		selectedOptions.userInformation.user.businessAddress.address2,
		selectedOptions.userInformation.user.businessRegistrationId,
		selectedOptions.userInformation.user.personalAddress,
		selectedOptions?.userInformation?.user?.personalAddressDetail,
		selectedOptions?.userInformation?.user?.companyName,
		file
	])

	const {
		handleSubmit, setValue, register, setError, formState: { errors } } = useForm({
			defaultValues: {
				userType: selectedOptions?.userInformation?.userType,
				name: selectedOptions?.userInformation?.user?.name,
				zip: selectedOptions?.userInformation?.user?.businessAddress?.zip,
				address1: selectedOptions?.userInformation?.user?.businessAddress?.address1,
				address2: selectedOptions?.userInformation?.user?.businessAddress?.address2,
				businessRegistrationId: selectedOptions?.userInformation?.user?.businessRegistrationId,
				businessRegistration: selectedOptions?.userInformation?.user?.businessRegistration,
				companyName: selectedOptions?.userInformation?.user?.companyName,
				personalAddress: selectedOptions?.userInformation?.user?.personalAddress,
				personalAddressDetail: selectedOptions?.userInformation?.user?.personalAddressDetail,
			}
		});

	const onFileUpload = async () => {
		// *** UPLOAD TO AZURE STORAGE ***
		const blobsInContainer = await uploadFile(fileManage);

		// reset state/form
		setFileManage(null);

		return blobsInContainer
	};

	const getChkAge = (data) => {
		return data ? 'O' : 'X'
	}




	const onSubmit = handleSubmit(async data => {
		let validCheck = true;

		if (!data?.name) {
			validCheck = false;
			setError('name', {
				type: 'focus',
				name: 'name',
				message: formErrorMessage.nameError
			}, { shouldFocus: true });
			return;
		}

		
		if (data?.name?.length <= 1) {
			validCheck = false;
			setError('name', {
				type: 'focus',
				name: 'name',
				message: '이름은 2자 이상 입니다.'
			}, { shouldFocus: true });
		}

		if (userType !== 'personal') {
			// if (!data?.zip) {
			// 	validCheck = false;
			// 	setError('zip', {
			// 		type: 'focus',
			// 		name: 'zip',
			// 		message: formErrorMessage.zipError,
			// 	}, { shouldFocus: true });
			// }

			if (!data?.companyName) {
				validCheck = false;
				setError('companyName', {
					type: 'focus',
					name: 'companyName',
					message: formErrorMessage.companyNameError
				}, { shouldFocus: true });
			}


			if (!data?.address1) {
				validCheck = false;
				setError('address1', {
					type: 'focus',
					name: 'address1',
					message: formErrorMessage.address1Error
				}, { shouldFocus: true });
			}

			if (!data?.address2) {
				validCheck = false;
				setError('address2', {
					type: 'focus',
					name: 'address2',
					message: formErrorMessage.address2Error
				}, { shouldFocus: true });
			}

			if (!data?.businessRegistrationId) {
				validCheck = false;
				setError('businessRegistrationId', {
					type: 'focus',
					name: 'businessRegistrationId',
					message: formErrorMessage.businessRegistrationIdError
				}, { shouldFocus: true });
			}

			if (!isExistsImages) {
				validCheck = false;
				setError('businessRegistration', {
					name: 'businessRegistration',
					message: formErrorMessage.businessRegistrationError
				});
			}
		}
		else {
			if (!data?.personalAddress) {
				validCheck = false;
				setError('personalAddress', {
					type: 'focus',
					name: 'personalAddress',
					message: formErrorMessage.address1Error
				}, { shouldFocus: true });
			}
			if (!data?.personalAddressDetail) {
				validCheck = false;
				setError('personalAddressDetail', {
					type: 'focus',
					name: 'personalAddressDetail',
					message: formErrorMessage.address2Error
				}, { shouldFocus: true });
			}
		}

		if (validCheck) {
			try {
				dispatch(openLoading())

				setSelectedOptions((prevState) => ({
					...prevState,
					userInformation: {
						...prevState.userInformation,
						userType: data?.userType,
						user: {
							...prevState.userInformation.user,
							name: data?.name,
							businessAddress: {
								...prevState.userInformation.user.businessAddress,
								zip: data?.zip,
								address1: data?.address1,
								address2: data?.address2,
							},
							businessRegistrationId: data?.businessRegistrationId,
							companyName: data?.companyName,
							personalAddress: data?.personalAddress,
							personalAddressDetail: data?.personalAddressDetail,
						}
					},
				}));
				dispatch(updateDriverDispatchUserInformation({
					userType: data?.userType ?? selectedOptions?.userInformation?.userType,
					user: {
						name: data?.name ?? null,
						zip: data?.zip ?? null,
						businessAddress: {
							zip: data?.zip ?? null,
							address1: data?.address1 ?? null,
							address2: data?.address2 ?? null,
						},
						businessRegistrationId: data?.businessRegistrationId ?? null,
						companyName: data?.companyName ?? null,
						personalAddress: data?.personalAddress ?? null,
						personalAddressDetail: data?.personalAddressDetail ?? null,
					}
				}))

				let resultUploadData = ""
				let uploadComplateFlag = false

				// 이미 사업자 등록증 업로드가 완료되었다면
				if (DriverDispatchOption?.userInformation?.user?.businessRegistration?.length >= 1) {
					uploadComplateFlag = true;
				}
				// 존재하지 않는다면?
				else {
					resultUploadData = await onFileUpload()
					setSelectedOptions((prevState) => ({
						...prevState,
						userInformation: {
							...prevState.userInformation,
							user: {
								...prevState.userInformation.user,
								businessRegistration: resultUploadData.url,
							}
						},
					}));
					dispatch(updateDriverDispatchImage(resultUploadData.url))
				}



				// 휴일이용:
				// 연봉:${DriverDispatchOption?.basicInfo2?.selectedSalary}만원
				let userInfoDecrypt = getDdEstimateCookieDataAndDecrypt();
				let ProductStr = `${getValueByTarget(DriverDispatchProducts, DriverDispatchOption?.subscription, 'sheetData')}${getValueByTarget(UsageTimeOptionsDriverDispatch, DriverDispatchOption?.usageTime, 'title')}`

				/*
				let ProductStr = `${getValueByTarget(DriverDispatchProducts, DriverDispatchOption?.subscription, 'sheetData')}${getValueByTarget(UsageTimeOptionsDriverDispatch, DriverDispatchOption?.usageTime, 'title')}
					옵션:${getValueByTarget(RecruitPurposeOption, DriverDispatchOption?.options?.recruitPurpose, 'sheetData')}, ${getValueByTarget(MonthlyMosilerOption, DriverDispatchOption?.options?.monthlyMosilerDemo, 'sheetData')}`
				*/
				const SubscriptionType = DriverDispatchOption?.subscription

				// selectedOptions.recruitRequest.



				let obj = {
					ContractName: data?.name,
					Address: (data?.userType === 'personal') ? data?.personalAddress + ' ' + data?.personalAddressDetail : data?.address1 + ' ' + data?.address2,
					CarInfo: '',		// 추가필요
					UsageDate: moment(DriverDispatchOption?.basicInfo1?.usageDate).format('yyyy-MM-DD'),
					// ContractMonth: (SubscriptionType === 'annual') ? '' : getValueByTarget(ContractPeriodOptions, DriverDispatchOption?.contractTerms1?.periodOfContract, 'title'),
					ContractMonth: '해당없음',
					NeedsString: getValueByTarget(RecruitPurposeOption, DriverDispatchOption?.options?.recruitPurpose, 'title'),	// 탑승자

					BookerName: userInfoDecrypt.UserName,
					BookerPhoneNumber: userInfoDecrypt.PhoneNumber,
					BookerEmailAddress: userInfoDecrypt.EmailAddress,
					// new 웹견적서
					UserType: (data?.userType === 'personal') ? '개인' : '법인',
					// 계약자 성함
					BusinessCode: data?.businessRegistrationId,
					BusinessLicense: uploadComplateFlag === true ? DriverDispatchOption?.userInformation?.user?.businessRegistration : resultUploadData.url,
					ContractProduct: ProductStr,
					recruitRequest: {
						workStartDate: DriverDispatchOption?.recruitRequest?.workStartDate,
						gender: getValueByTarget(GenderOption, DriverDispatchOption?.recruitRequest?.gender, 'title'),
						age20: getChkAge(DriverDispatchOption?.recruitRequest?.age20),
						age30: getChkAge(DriverDispatchOption?.recruitRequest?.age30),
						age40: getChkAge(DriverDispatchOption?.recruitRequest?.age40),
						age50: getChkAge(DriverDispatchOption?.recruitRequest?.age50),
						age60: getChkAge(DriverDispatchOption?.recruitRequest?.age60),
						ageboth: getChkAge(DriverDispatchOption?.recruitRequest?.ageboth),
						driverSmokingStatus: getValueByTarget(DriverSmokingStatusOption, DriverDispatchOption?.recruitRequest?.driverSmokingStatus, 'title'),
						driverParkingSupport: getValueByTarget(DriverParkingSupportOption, DriverDispatchOption?.recruitRequest?.driverParkingSupport, 'title'),
						commutingVehicleProvided: getValueByTarget(CommutingVehicleProvidedOption, DriverDispatchOption?.recruitRequest?.commutingVehicleProvided, 'title'),
						postHireJobResponsibilities: DriverDispatchOption?.recruitRequest?.postHireJobResponsibilities,
						benefits: DriverDispatchOption?.recruitRequest?.benefits,
						vehicleModelName: DriverDispatchOption?.recruitRequest?.vehicleModelName,
						foreignLanguageProficiency: getValueByTarget(ForeignLanguageProficiencyOption, DriverDispatchOption?.recruitRequest?.foreignLanguageProficiency, 'title'),
						foreignLanguageProficiencyStr: DriverDispatchOption?.recruitRequest?.foreignLanguageProficiencyStr,
						driverWaitingRoomAvailable: getValueByTarget(DriverWaitingRoomAvaliableOption, DriverDispatchOption?.recruitRequest?.driverWaitingRoomAvailable, 'title'),
						actualWorkLocation: DriverDispatchOption?.basicInfo2?.companyAddress,
						educationalBackground: getValueByTarget(EducationalBackgroundOption, DriverDispatchOption?.recruitRequest?.educationalBackground, 'title'),
						specialNotes: DriverDispatchOption?.recruitRequest?.specialNotes
					}
				}

				setSheetContractResponse(obj)
				SendSlackDdContracting(obj)


				SetMmEstimateHistory({
					...getDdEstimateCookieDataAndDecrypt(),
					EstimateType: ESTIMATE_TYPE_DRIVER_DISPATCH,
					EstimatePage: PAGE.PAGE_08,
					JsonData: JSON.stringify({
						...selectedOptions,
						userInformation: {
							...selectedOptions.userInformation,
							userType: data?.userType,
							user: {
								...selectedOptions.userInformation.user,
								name: data?.name,
								businessAddress: {
									...selectedOptions.userInformation.user.businessAddress,
									zip: data?.zip,
									address1: data?.address1,
									address2: data?.address2,
								},
								businessRegistrationId: data?.businessRegistrationId,
								companyName: data?.companyName,
								personalAddress: data?.personalAddress,
								personalAddressDetail: data?.personalAddressDetail,
							}
						},
					})
				})





				/*
				let form = qs.stringify({
					"1. 계약자의 성함을 알려주세요.": obj.ContractName,
					"2. 계약자의 상세 주소를 알려주세요.": obj.ContractAddress + ' ' + obj.ContractAddress2,
					"3. 차량의 모델명을 알려주세요": obj.CarModel,
					"4. 이용 시작일은 언제 부터 인가요?  ": `${moment(obj.ContractStartDate).format('yyyy-MM-DD')} (${getEnglishDayToKorean(moment(obj.ContractStartDate).format('ddd'))})`,
					"5. 원하시는 계약 기간은 어떻게 되시나요? ": obj.ContractMonthString,
					"6. 서비스 이용의 목적은 어떻게 되시나요?": obj.PurposeString === '기타' ? `기타(${obj.PurposeEtcStr})` : obj.PurposeString,
					"7. 담당자님의 성함을 알려주세요": obj.BookerName,
					"8. 담당자님의 연락처를 알려주세요": obj.BookerPhone,
					"9. 담당자님의 이메일 주소를 알려주세요": obj.BookerEmail,
					'10. 컨시어지 상담을 원하시면 알려주세요.': '웹견적서',
					'타임스탬프': moment().format('yyyy-MM-DD HH:mm'),
					// "전화번호" : userinfo.UserPhone,
					// "이메일주소" : userinfo.UserEmail,
					"고객유형": obj.CustomerTypeString,
					"계약자 성함": obj.ContractName,
					"사업자등록번호": (obj.CustomerType === '1') ? '없음(개인고객)' : obj.ContractBLNumber,
					"사업자 등록증": (obj.CustomerType === '1') ? '없음(개인고객)' : obj.ComplateBusinessLicenseUrl,
					"계약요청상품": (obj.ContractReqProductString === undefined || obj.ContractReqProductString === null) ? '' : obj.ContractReqProductString
				});


				

	  let ProductStr = `${getValueByTarget(DriverDispatchProducts, DriverDispatchOption?.subscription, 'sheetData')}
	${DriverDispatchOption?.basicInfo1?.workStartTime}~${DriverDispatchOption?.basicInfo1?.workEndTime}
	휴일이용:${getValueByTarget(WeekendUsageOptions, DriverDispatchOption?.basicInfo1?.weekendUsage, 'title')}
	연봉:${DriverDispatchOption?.basicInfo2?.selectedSalary}만원
	옵션:${getValueByTarget(RecruitPurposeOption, DriverDispatchOption?.options?.recruitPurpose, 'sheetData')}, ${getValueByTarget(MonthlyMosilerOption, DriverDispatchOption?.options?.monthlyMosilerDemo, 'sheetData')}`

	  await setSheetEstimateResponse({
		UserName: 'test',
		UserPhone: '01011112222',
		UserEmail: 'kimpaksa@mkikm.mkikm',
		StartAddress: DriverDispatchOption?.basicInfo2?.garageAddress,
		EndAddress: DriverDispatchOption?.basicInfo2?.companyAddress,
		UsageSchedule: moment(DriverDispatchOption?.basicInfo1?.usageDate).format('yyyy-MM-DD'),
		Route: getValueByTarget(RouteOptions, DriverDispatchOption?.basicInfo3?.route, 'title'),
		Requestcomment: getValueByTarget(EtcRequestOptions, DriverDispatchOption?.basicInfo3?.etcRequest, 'title'),
		ConciergeString: getValueByTarget(ConciergeAdviceOptions, DriverDispatchOption?.basicInfo3?.conciergeAdvice, 'title'),
		NeedsString: getValueByTarget(RecruitPurposeOption, DriverDispatchOption?.options?.recruitPurpose, 'title'),
		SelectedProductStr: ProductStr,
	  })

				*/

				// userType  personal, corporate




				dispatch(closeLoading())

			} catch (e) {
				dispatch(closeLoading())
			}

			// 결제 화면은 Todo
			// navigate(URLS.BOOKING_ESTIMATION_PAYMENT_METHOD)
			// navigate(URLS.DRIVER_DISPATCH_CONFIRM_CONTRACT)
			if (DriverDispatchOption?.subscription === 'recruit') {
				navigate(URLS.DRIVER_DISPATCH_CONFIRM_CONTRACT)
			}
			else {
				navigate(URLS.DRIVER_DISPATCH_CONFIRM_DISPATCH_REQUEST)
			}


		}
	});

	const handleAddressChange = (subField, value, target) => {
		setSelectedOptions((prevState) => ({
			...prevState,
			userInformation: {
				...prevState.userInformation,
				user: {
					...prevState.userInformation.user,
					[target]: {
						...prevState.userInformation.user[target],
						[subField]: value,
					},
				},
			},
		}));
	};

	const handleAddressChange2 = (subField, value, target) => {
		setSelectedOptions((prevState) => ({
			...prevState,
			userInformation: {
				...prevState.userInformation,
				user: {
					...prevState.userInformation.user,
					[target]: value
				},
			},
		}));
	};

	const handleAddressChange3 = (subField, value, target) => {
		setSelectedOptions((prevState) => ({
			...prevState,
			userInformation: {
				...prevState.userInformation,
				user: {
					...prevState.userInformation.user,
					businessAddress: {
						...prevState.userInformation.user.businessAddress,
						[target]: value
					}
				},
			},
		}));
	};

	const handleImages = (e) => {


		if (e.target.files.length) {
			setFile(e.target.files[0].name);
			setFileManage(e.target.files[0])
		}
		else {
			setFile('');
			setFileManage(null)
		}

		// const target = {
		// 	images: Array.from(e.target.files),
		// };
		// if (target) {

		// 	// setError('businessRegistration', null);
		// 	// setValue('businessRegistration', target)
		// }

	};

	return (
		<>
			<div className={classNames('booking-wrap')}>
				<PageTitle title={pageTitleDesc.title} subtitle={pageTitleDesc.subtitle} />
				<form className={classNames('booking-form')} onSubmit={onSubmit}>
					<div className={classNames('booking-form-list')}>
						{!!UserTypeOptions && (
							<div className={'booking-form-item'}>
								<span className={classNames('title-mid')}>고객 유형</span>
								<RadioGroup
									classes={''}
									legend={'purposeOfContract'}
									selected={selectedOptions.userInformation.userType}
								>
									<ul className={classNames('booking-item-list row')}>
										{UserTypeOptions.map((item) => (
											<li key={item.title}>
												<Radio
													key={item.title}
													value={item.value}
													onChange={e => {
														setValue('userType', item.value);
														setUserType(item.value)
														setSelectedOptions((prevState) => ({
															...prevState,
															userInformation: {
																...prevState.userInformation,
																userType: item.value,
															},
														}));
													}}
												>
													<BookingItem
														selected={selectedOptions.userInformation.userType === item.value}
														subtitle={item?.title}
														isAlignCenter={true}
													/>
												</Radio>
											</li>
										))}
									</ul>
								</RadioGroup>
							</div>
						)}

						<div className={'booking-form-item'}>
							<span className={classNames('title-mid')}>
								{pageTitleDesc.desc1}
							</span>
							<Input
								type={'text'}
								{...register('name')}
								required
								setValue={setValue}
								error={errors.name}
								onChange={e => {
									setValue('name', e.target.value)
									setSelectedOptions((prevState) => ({
										...prevState,
										userInformation: {
											...prevState.userInformation,
											user: {
												...prevState.userInformation.user,
												name: e.target.value
											},
										},
									}));
								}}
								placeholder={pageTitleDesc.desc1}

							/>
						</div>

						{userType === 'personal' && (
							<div className={'booking-form-item'}>
								<span className={classNames('title-mid')}>
									{pageTitleDesc.desc2}
								</span>
								<AddressInput2
									register={register}
									setValue={setValue}
									// zipError={errors.zip}
									address1Error={errors.personalAddress}
									// address2Error={errors.address2}
									// zipRegister={'zip'}
									address1Register={'personalAddress'}
									// address2Register={'address2'}
									setSelectedOptions={setSelectedOptions}
									handleAddressChange={handleAddressChange2}
									target={'personalAddress'}
									placeholder={'주소지를 입력해주세요'}
								/>

								<Input
									type={'text'}
									required
									setValue={setValue}
									error={errors.personalAddressDetail}
									{...register('personalAddressDetail')}
									onChange={e => {
										setValue('personalAddressDetail', e.target.value)
										setSelectedOptions((prevState) => ({
											...prevState,
											userInformation: {
												...prevState.userInformation,
												user: {
													...prevState.userInformation.user,
													personalAddressDetail: e.target.value
												},
											},
										}));
									}}
									placeholder={'상세주소를 입력해주세요'}
								/>
							</div>
						)}



						{userType === 'corporate' && (
							<>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>회사명</span>
									<Input
										type={'text'}
										required
										{...register('companyName')}
										setValue={setValue}
										error={errors.companyName}
										onChange={e => {
											setValue('companyName', e.target.value)
											setSelectedOptions((prevState) => ({
												...prevState,
												userInformation: {
													...prevState.userInformation,
													user: {
														...prevState.userInformation.user,
														companyName: e.target.value
													},
												},
											}));
										}}
										placeholder={'회사명'}

									/>
								</div>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>사업자 등록증상의 주소</span>
									<AddressInput2
										register={register}
										setValue={setValue}
										// zipError={errors.zip}
										address1Error={errors.address1}
										// address2Error={errors.address2}
										// zipRegister={'zip'}
										address1Register={'address1'}
										// address2Register={'address2'}
										setSelectedOptions={setSelectedOptions}
										handleAddressChange={handleAddressChange3}
										target={'address1'}
									/>
									<Input
										type={'text'}
										required
										setValue={setValue}
										error={errors.address2}
										{...register('address2')}
										onChange={e => {
											setValue('address2', e.target.value)
											setSelectedOptions((prevState) => ({
												...prevState,
												userInformation: {
													...prevState.userInformation,
													user: {
														...prevState.userInformation.user,
														businessAddress: {
															...prevState.userInformation.user.businessAddress,
															address2: e.target.value
														}
													},
												},
											}));
										}}
										placeholder={'상세주소를 입력해주세요'}
									/>
								</div>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>사업자 등록번호</span>
									<Input
										type={'text'}
										required
										{...register('businessRegistrationId')}
										setValue={setValue}
										error={errors.businessRegistrationId}
										onChange={e => {
											setValue('businessRegistrationId', e.target.value)
											setSelectedOptions((prevState) => ({
												...prevState,
												userInformation: {
													...prevState.userInformation,
													user: {
														...prevState.userInformation.user,
														businessRegistrationId: e.target.value
													},
												},
											}));
										}}
										placeholder={'사업자 등록번호'}
									/>
								</div>
								<div className={'booking-form-item'}>
									<span className={classNames('title-mid')}>사업자 등록증 첨부</span>
									<div className={classNames('file-form-wrap')}>
										<div className={'file-form-set'}>
											<div className={'file-form-set'}>
												<div className={classNames('file-button-set')}>
													<input
														{...register('businessRegistration')}
														className='hidden'
														ref={inputRef}
														type='file'
														accept='image/*, application/pdf'
														onChange={handleImages} />
													{/* <input ref={inputRef} type='file' accept='image/*, application/pdf' onChange={handleImages} /> */}
													<Button
														styleType='icon'
														onClick={() => inputRef.current.click()}
													// disabled={isExistsImages}
													>
														<Icon icon={'attach'} />
														파일 첨부
													</Button>
												</div>
												{fileManage && <span>{fileManage?.name}</span>}
												{/* {isExistsImages && <span>파일 업로드가 완료되었습니다.</span>} */}

											</div>
											{errors.businessRegistration && !file && (
												<span className={classNames('error')}>
													{errors.businessRegistration.message}
												</span>
											)}
										</div>
									</div>
								</div>
							</>
						)}
					</div>
					<BookingFormSummary
						hasAmonut={false}
						description={description}>
						<div className={classNames('button-set')}>
							<Button
								onClick={
									() => navigate(URLS.DRIVER_DISPATCH_RECRUIT_REQUEST)
								}
							>이전</Button>
							<Button
								styleType={'secondary'}
								type={'submit'}
							>다음</Button>
						</div>
					</BookingFormSummary>
				</form>
			</div>
		</>
	)
}

export default UserInformation;